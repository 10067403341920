<div class="ospiti-wrapper" *ngIf="{val: posti$ | async} as posti">
    <div class="label">Ospiti</div>
    <div class="nomi-ospiti" *ngIf="ospiti$ | async as ospiti">
        <div class="ospite" *ngFor="let o of ospiti">
            <div class="nome-ospite">
                <div class="nome" [style]="o.minorenne ? 'color: red;': ''">{{o.nome}}</div>
                <div class="cognome" [style]="o.minorenne ? 'color: red;': ''">{{o.cognome}}</div>
            </div>
            <ion-button *ngIf="redirect$ | async as cd" size="small" class="round" (click)="modifica(o)">i</ion-button>
        </div>
    </div>
    <ion-button [disabled]="posti.val === 0" *ngIf="tastoAdd" (click)="aggiungi()">+</ion-button>    
</div>

