import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ICGiorno } from '../../api/calendario.service';
import { CalendarioService } from '../../api/calendario.service';
import * as dayjs from 'dayjs';
import { MezzoDiTrasporto, MDTConfigService, MDTConfig } from '../../api/mezzo-di-trasporto.service';
import { UscitaService, Uscita } from '../../api/uscita.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PosizionePrenotata, PosizionePrenotataService } from '../../api/posizione-prenotata.service';
import { PosizioneSulMezzoService } from '../../api/posizione-sul-mezzo.service';
import { FirebaseService } from '../../api/firebase.service';
import { UserService } from '../../api/user.service';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ModalController } from '@ionic/angular';
import { ProblemsHistoryComponent } from '../problems-history/problems-history.component';
import { AlertController } from '@ionic/angular';
import { first } from 'rxjs/operators';


@UntilDestroy()
@Component({
  selector: 'ic-calendar-detail',
  templateUrl: './ic-calendar-detail.component.html',
  styleUrls: ['./ic-calendar-detail.component.scss']
})
export class IcCalendarDetailComponent implements OnInit, OnDestroy {

  public selectedDay$: Observable<ICGiorno>;
  public curSelectedDay: ICGiorno;
  public confMezzi$: Observable<MDTConfig[]>;
  public confMezzi: Array<MDTConfig>;
  public canBook$: Observable<boolean>;
  public canBook: boolean;
  public mostraForm: boolean;
  public mezziDisponibili$: Observable<MezzoDiTrasporto[]>;
  public orario: string;
  public orariorientro: string;
  public mezzo: string;
  public target: string;
  public showMaintenance: boolean;
  public manutenzione: boolean;
  private uSvcSubscription: Subscription ;

  constructor(private calSvc: CalendarioService,
              private uSvc: UscitaService,
              private ppSvc: PosizionePrenotataService,
              private psmSvc: PosizioneSulMezzoService,
              private mdtcSvc: MDTConfigService,
              private userSvc: UserService,
              private fbSvc: FirebaseService,
              private modalController: ModalController,
              private alertController: AlertController) { }

  ngOnInit(): void {
    this.showMaintenance = false;
    this.manutenzione = false;
    this.selectedDay$ = this.calSvc.getGiornoSelezionato();

    this.confMezzi = Array();
    this.confMezzi$ = this.mdtcSvc.getList();
    this.confMezzi$.pipe(untilDestroyed(this)).subscribe(confs => {
      this.confMezzi = confs;
    });

    this.selectedDay$.pipe(untilDestroyed(this)).subscribe((selday: ICGiorno) => {
      this.curSelectedDay = selday;

      if (this.uSvcSubscription) {
        this.uSvcSubscription.unsubscribe();
      }
      this.uSvcSubscription = this.uSvc.getListForDay(selday.iso8601).subscribe(uscite => {
        this.mezziDisponibili$ =
          this.uSvc.getMezziDisponibili(selday.iso8601, uscite);
        const tmpSubscr = this.mezziDisponibili$.subscribe((mezzi: MezzoDiTrasporto[]) => {
          let mezzoSel = undefined;
          for (let i = 0; i < mezzi.length; i++) {
            const possibileMezzoSelezionato = mezzi[(i + selday.numero) % mezzi.length];
            if (this.mezzoPrenotabile(possibileMezzoSelezionato)) {
              mezzoSel = possibileMezzoSelezionato;
              break;
            }
          }
          if (mezzoSel) {
            this.mezzo = mezzoSel.id;
          } else {
            this.mezzo = 'none';
          }
        });
        const unsubF = () => {
          if (this.mezzo) { // la subscribe ha completato la sua esecuzione
            tmpSubscr.unsubscribe(); // quindi non mi serve più
          } else {
            setTimeout(() => { unsubF();}, 200); // altrimenti ci riprovo fra poco (non posso passare direttamente unsubF perché non è ancora inizializzata)
          }
        };

        unsubF();

      });

      this.userSvc.canRedirect(this.fbSvc.getUserId()).pipe(untilDestroyed(this)).subscribe((canRedir: boolean) => {
        this.showMaintenance = canRedir;
        this.userSvc.canBook(this.fbSvc.getUserId()).pipe(untilDestroyed(this)).subscribe((canBook: boolean) => {
          this.calSvc.segnaleOrarioIso8601().pipe(untilDestroyed(this)).subscribe((iso8601: string) => {
            /*

              Tutto questo doveva servire a non mostrare il tasto "Aggiungi uscita" quando il cliente
              voleva che le uscite non si potessero aggiungere con meno di 24 ore di anticipo.
              Questa limitazione di 24 ore è stata poi rimossa su richiesta del cliente e questo
              segnale orario ad oggi non serve più a nulla, ma lo tengo qui perché conosco i miei polli
              e sospetto che prima o poi mi chiederà di riaggiungerla, probabilmente in forma
              un pochino diversa e più complessa... 

            this.canBook = 
              (canRedir && dayjs(selday.iso8601).diff(iso8601, 'hour') > 0) ||
              (canBook && dayjs(selday.iso8601).diff(iso8601, 'hour') >= 24);
              */ 

            /* la nuova richiesta è che le uscite si possano programmare in qualsiasi momento. Nascondo il
            tasto solo quando l'intera giornata selezionata è già trascorsa (quindi sono già passate più
              di 24 ore dall'inizio della giornata selezionata)  */              
            this.canBook = (canRedir || canBook) && dayjs(iso8601).diff(selday.iso8601, 'hour') < 24;
                
          });
        });        
      });
    });

    this.orario = dayjs().startOf("day").add(10, 'hour').format();
    this.orariorientro = dayjs().startOf("day").add(13, 'hour').format();
  }

  ngOnDestroy(): void {
    if (this.uSvcSubscription) {
      this.uSvcSubscription.unsubscribe();
    }
}

  public selectedDay(selDay: ICGiorno): string {
    var localizedFormat = require('dayjs/plugin/localizedFormat');
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(localizedFormat);
    return dayjs(selDay.iso8601).tz('Asia/Dubai').format('L');
  }

  public toggleFormUscita() {
    this.mostraForm = !this.mostraForm;
  }

  public getStyle(m: MezzoDiTrasporto): string {

    return this.mezzoPrenotabile(m) ? "background-image: url('/assets/" + m.immagine + "');" :
                                "background-image: url('/assets/soldout.png');"
  }

  public forwardClick(elem: MezzoDiTrasporto) {
    if (this.mezzoPrenotabile(elem)) {
      document.getElementById(elem.id).click();
    }
  }

  private dstSkew(selDay: ICGiorno, p_timezone: string): number {
    // devo calcolare il numero di ore che passano da oggi al giorno selezionato sia qui dove mi trovo
    // che nella timezone specificata. Se le due differenze non coincidono,
    // significa che qui o là c'è un cambio di ora legale (o in entrambi i posti, ma di entità diversa).
    // Devo quindi ritornare la differenza fra le due differenze, in modo da salvare sul db non l'ora
    // che attualmente c'è nel posto dove ho prenotato l'uscita, ma l'ora che ci sarà quando si farà
    // l'uscita.

    dayjs.extend(utc);
    dayjs.extend(timezone);

    dayjs.tz.setDefault(); // mi metto nel fuso locale con la DST locale
    const giornoAttualeFusoLocale = dayjs().startOf('day').format("YYYY-MM-DD");
    const le12DiOggiFusoLocale_str = giornoAttualeFusoLocale + " 12:00";
    const le12DiOggiFusoLocale = dayjs(le12DiOggiFusoLocale_str);

    const giornoSelFusoLocale = dayjs(selDay.iso8601).startOf('day').format("YYYY-MM-DD");
    const le12DelGiornoSelFusoLocale_str = giornoSelFusoLocale + ' 12:00';
    const le12DelGiornoSelFusoLocale = dayjs(le12DelGiornoSelFusoLocale_str);

    dayjs.tz.setDefault(p_timezone); // ora mi sposto nel fuso richiesto

    const giornoAttualeFusoDestinazione = dayjs().tz(p_timezone).startOf('day').format("YYYY-MM-DD");
    const le12DiOggiFusoDestinazione_str = giornoAttualeFusoDestinazione + " 12:00";
    const le12DiOggiFusoDestinazione = dayjs(le12DiOggiFusoDestinazione_str).tz(p_timezone);

    const giornoSelFusoDestinazione = dayjs(selDay.iso8601).tz(p_timezone).startOf('day').format("YYYY-MM-DD");
    const le12DelGiornoSelFusoDestinazione_str = giornoSelFusoDestinazione + ' 12:00';
    const le12DelGiornoSelFusoDestinazione = dayjs(le12DelGiornoSelFusoDestinazione_str).tz(p_timezone);

    const diffFusoLocale = le12DelGiornoSelFusoLocale.diff(le12DiOggiFusoLocale);
    const diffFusoDestinazione = le12DelGiornoSelFusoDestinazione.diff(le12DiOggiFusoDestinazione);

    const result = (diffFusoLocale - diffFusoDestinazione) / 1000 / 60 / 60; // da millis ad ore
    return result % 24;
  
  }

  public salva(selDay: ICGiorno) {
    if (this.mezzo === 'none') {
      return;
    }

    if (typeof this.target === 'undefined' || this.target === null || this.target.trim().length === 0) {
      this.presentAlert("Manca il target", "L'inserimento del target è obbligatorio");
      return;
    }

    const usc = new Uscita();
    usc.idMezzo = this.mezzo;
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const startOfDay = dayjs(selDay.iso8601).tz('Asia/Dubai').startOf('day'); 
    usc.iso8601 = startOfDay.add(((dayjs(this.orario).tz('Asia/Dubai').hour() + this.dstSkew(selDay, 'Asia/Dubai')) 
                                                * 60 + 
                                                dayjs(this.orario).tz('Asia/Dubai').minute()), 'minute').
                                                format();
    usc.iso8601rientro = startOfDay.add(((dayjs(this.orariorientro).tz('Asia/Dubai').hour() + this.dstSkew(selDay, 'Asia/Dubai'))
                                                * 60 + 
                                                dayjs(this.orariorientro).tz('Asia/Dubai').minute()), 'minute').
                                                format();

    usc.manutenzione = this.manutenzione;
    if (usc.manutenzione) {
      usc.annullata = true;
    } else {
      usc.id_utente_master = this.fbSvc.getUserId();
    }

    usc.target = this.target;

    const sub = this.uSvc.getListForDayAndMezzo(startOfDay.format(), usc.idMezzo).pipe(first()).subscribe(
      async (uscite: Uscita[]) => {
        sub.unsubscribe();
        for (let i = 0; i < uscite.length; i++) {
          if (this.uSvc.usciteSovrapposte(usc, uscite[i])) {
            await this.presentAlert("Modifica orario", "Questa barca uscirà già nella stessa giornata: verifica gli orari in modo da lasciare almeno mezz'ora di intervallo fra le uscite.");
            return;
          }
        }
        this.uSvc.create(usc).then(_result => {
          // di default chi crea l'uscita occupa anche la posizione principale, ma
          // resta libero di cambiarla successivamente
          
          if (!usc.manutenzione) {
            const posizionePrincipale = this.psmSvc.getPosizionePrincipale(usc.idMezzo);
            const v = new PosizionePrenotata();
            Object.assign(v, {...posizionePrincipale});
            v.id_utente = usc.id_utente_master;
            this.ppSvc.create(v, usc).then((result) => {
              console.log(result);
            }).catch(error => { 
              console.error(error);
            });
            this.manutenzione = false;
            this.target = '';
          }     
        }).catch(error => { console.log(error); 
        });
        
        this.toggleFormUscita();
      });

  }

  public mezzoPrenotabile(m: MezzoDiTrasporto): boolean {
    return m.prenotabile > 0;
  }

  async showProblems(idMezzo: string) {

    const modal = await this.modalController.create({
      component: ProblemsHistoryComponent,
      componentProps: {
        'pending': true,
        'mezzo': idMezzo
      },
      swipeToClose: true
    });

    return await modal.present();    
  }

  async presentAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      cssClass: 'alert',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'alert-button-cancel',
        }
      ],
    });

    await alert.present();
  }

  manutClick() {
    if (!this.manutenzione) {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      const selDay = dayjs(this.curSelectedDay.iso8601).tz('Asia/Dubai');
      this.orario = selDay.startOf('day').format();
      this.orariorientro = selDay.endOf('day').format();
      this.target = 'Maintenance';
    }
  }
}
