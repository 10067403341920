import { Component, Input, OnInit } from '@angular/core';
import { Uscita } from '../../api/uscita.service';
import { PosizionePrenotataService } from '../../api/posizione-prenotata.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OspiteService } from '../../api/ospite.service';
import { MezzoDiTrasportoService, MDTConfig, MDTConfigService } from '../../api/mezzo-di-trasporto.service';

@UntilDestroy()
@Component({
  selector: 'ic-calendar-marker',
  templateUrl: './ic-calendar-marker.component.html',
  styleUrls: ['./ic-calendar-marker.component.scss'],
})
export class IcCalendarMarkerComponent implements OnInit {

  @Input() uscita: Uscita;
  @Input() mdtConfigs: MDTConfig[];

  public cssCode$: Observable<string>;
  public colore$: Subject<string> = new Subject();

  constructor(private ppSvc: PosizionePrenotataService,
              private ospSvc: OspiteService,
              private mdtSvc: MezzoDiTrasportoService,
              private mdtcSvc: MDTConfigService) { }

  ngOnInit() {
    if (this.nonManut()) {
      this.cssCode$ = this.colore$.pipe(map(colore => {
        return "background-color: " + colore + ";"; 
      }));
      this.ppSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(
          prenotazioni => {
            this.ospSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(
              ospiti => {
                const mezzo = this.mdtSvc.getMezzoDaID(this.uscita.idMezzo);
                const maxPers = this.mdtcSvc.getConfigDaID(mezzo.id, this.mdtConfigs).maxPersoneABordo;
                this.colore$.next(this.ppSvc.getColoreUscita(this.uscita, prenotazioni.length, ospiti.length, maxPers));
              })});
  
      this.cssCode$ = this.colore$.pipe(map(colore => {
        return "background-color: " + colore + ";"; 
      }));
    } else {
      this.cssCode$ = new BehaviorSubject("background-image: url('" + 
      "/assets/maintenance.png" +
      "'); background-size: contain;");
    }
  }

  public nonManut(): boolean {
    return typeof (this.uscita.manutenzione) === 'undefined' ||
                   this.uscita.manutenzione === false ||
                    this.uscita.manutenzione === null; 
  }

}
