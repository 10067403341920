import { Uscita } from './../../api/uscita.service';
import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { UscitaService } from '../../api/uscita.service';
import { FirebaseService } from '../../api/firebase.service';
import { UserService, Utente } from '../../api/user.service';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';


@Component({
  selector: 'outstanding-problems',
  templateUrl: './outstanding-problems.component.html',
  styleUrls: ['./outstanding-problems.component.scss']
})
export class OutstandingProblemsComponent implements OnInit {

  @ViewChild(IonContent, { static: false }) content: IonContent;

  @Input() uscita: Uscita;
  
  public testoTastoSalva: string;

  problemiForm: FormGroup;

  constructor(
    private modalController: ModalController,
    private uscitaSvc: UscitaService,
    private fbSvc: FirebaseService,
    private userSvc: UserService
  ) { }

  ngOnInit() {
    this.testoTastoSalva = this.uscita.tl_mantieni_ticket_aperto ? "Salva" : "Salva e chiudi";
    this.problemiForm = new FormGroup({
      fc_risolti_il: new FormControl(this.uscita.tl_problemi_risolti_il),
      fc_risolti_da: new FormControl(this.uscita.tl_problemi_risolti_da),
      fc_risolti_con: new FormControl(this.uscita.tl_problemi_risolti_con, Validators.required),
      fc_mantieni_ticket_aperto: new FormControl(this.uscita.tl_mantieni_ticket_aperto)
    });
  }


  dismissModal(output = null) {
   this.modalController.dismiss(output);
  }

  private async bindData() {

    dayjs.extend(utc);
    dayjs.extend(timezone);  
    dayjs.extend(customParseFormat);  

    const data = this.problemiForm.value.fc_risolti_il ?? dayjs().format('YYYY-MM-DD');
    const chi = this.problemiForm.value.fc_risolti_da ?? await this.nomeUtenteAttuale();

    this.uscita.tl_problemi_risolti_il = data;
    this.uscita.tl_problemi_risolti_da = chi;
    this.uscita.tl_problemi_risolti_con = this.problemiForm.value.fc_risolti_con;
    this.uscita.tl_mantieni_ticket_aperto = this.problemiForm.value.fc_mantieni_ticket_aperto;
    this.uscita.tl_ticket_problemi_chiuso_da = this.fbSvc.getUserId();
  }

  async update() {
    await this.bindData();
    this.uscitaSvc.update(this.uscita);
    this.dismissModal();
  }

  async nomeUtenteAttuale(): Promise<string> {
    const that = this;
    return new Promise<string>((resolve, _reject) => {
      const sub = this.userSvc.get(this.fbSvc.getUserId()).subscribe(user => {
        sub.unsubscribe();
        const result = user.nome + ' ' + user.cognome;
        resolve(result); 
      });
    });
  }

  checkChanged(event: any) {
    this.testoTastoSalva = event.detail.checked ? "Salva" : "Salva e chiudi";
  }

}
