import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
require('dayjs/locale/it');
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export class ICGiorno {
  public iso8601: string;
  public label: string;
  public note: string;
  public parteDelMese: boolean;
  public settimana: number;
  public numero: number;
}


@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  private startDate: dayjs.Dayjs;
  private giorni: BehaviorSubject<ICGiorno[]>;
  private settimane: BehaviorSubject<ICGiorno[]>[];
  private numeroSettimane: BehaviorSubject<number>;
  private giornoSelezionato: ReplaySubject<ICGiorno>;
  private segnaleOrario: ReplaySubject<string>;

  constructor() { 
    this.giorni = null;
    this.numeroSettimane = null;
    this.settimane = Array<BehaviorSubject<ICGiorno[]>>();
    const selezioneIniziale = new ICGiorno();
    const oggi = dayjs();
    selezioneIniziale.iso8601 = oggi.format();
    selezioneIniziale.label = oggi.date() + '';
    selezioneIniziale.numero = oggi.date();
    this.giornoSelezionato = new ReplaySubject<ICGiorno>(1);
    this.giornoSelezionato.next(selezioneIniziale);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    this.segnaleOrario = new ReplaySubject<string>(2, 120000);
    this.segnaleOrario.next(dayjs().tz('Asia/Dubai').format());
    setInterval(() => { 
        dayjs.extend(utc);
        dayjs.extend(timezone);
        const adesso = dayjs().tz('Asia/Dubai').format();
        this.segnaleOrario.next(adesso);  
    }, 60000); // un tick ogni minuto
  }

  private static initGiorni(data: dayjs.Dayjs = null): Array<ICGiorno> {
    if (data == null) {
      data = dayjs();
    }
    // Trovo il primo del mese
    const primodelmese = data.startOf('month');
    // trovo il lunedì precedente al primo del mese
    const iniziocalendario = primodelmese.startOf('week');
    // trovo l'ultimo giorno del mese
    const ultimodelmese = primodelmese.endOf('month');
    // trovo l'ultima domenica del mese
    const finecalendario = ultimodelmese.endOf('week');

    const numeroGiorni = finecalendario.diff(iniziocalendario, 'day') + 1;
    const result = Array<ICGiorno>();
    for (let i = 0; i < numeroGiorni; i++) {
      const g = new ICGiorno();
      const giorno = iniziocalendario.add(i, 'day');
      g.label = giorno.date() + '';
      g.parteDelMese = !(giorno.isBefore(primodelmese) || giorno.isAfter(ultimodelmese));
      g.settimana = Math.floor(i/7);
      g.iso8601 = giorno.format();
      g.numero = giorno.date();
      result.push(g);
    }

    return result;
  }

  public getGiorniMese(): Observable<ICGiorno[]> {

    this.cs_next();

    return this.giorni;
  }

  public getGiorniSettimana(numerosett: number): Observable<ICGiorno[]> {

    this.cs_next();

    return this.settimane[numerosett];
  }

  public getSettimaneMese(): Observable<number> {
    return this.numeroSettimane;
  }

  private initDefaultStartDate(): void {
    if (!this.startDate) {
      dayjs.locale('it');
      dayjs.extend(utc);
      dayjs.extend(timezone);
      this.startDate = dayjs().tz('Asia/Dubai').startOf('month');
    }
  }

  private cs_next(oggiDjs = null) {
    
    this.initDefaultStartDate();

    if (oggiDjs == null) {
      oggiDjs = this.startDate;
    }

    if (this.giorni == null || !this.startDate.isSame(oggiDjs)) {
      this.startDate = oggiDjs;

      const elencoGiorni = CalendarioService.initGiorni(oggiDjs);

      if (this.giorni == null) {
        this.giorni = new BehaviorSubject<ICGiorno[]>(elencoGiorni);
      } else {
        this.giorni.next(elencoGiorni);
      }

      const divisiPerSettimana: Array<ICGiorno[]> = CalendarioService.splitWeeks(elencoGiorni);

      for (let i = 0; i < divisiPerSettimana.length; i++) {
        if (this.settimane.length <= i) {
          this.settimane.push(new BehaviorSubject<ICGiorno[]>(divisiPerSettimana[i]));
        } else {
          this.settimane[i].next(divisiPerSettimana[i]);
        }
      }

      // se sono passato da un mese che si spalma su 6 settimane (tipo agosto 2021)
      // ad un mese che ne richiede solo 5 (tipo settembre 2021) meglio svuotare
      // l'array di giorni dell'ultima settimana, perché nel ciclo precedente
      // non ha fatto next sull'ultima settimana (mancando i giorni del mese per
      // quella settimana) e senza svuotarlo mostrerebbe i giorni del mese prima. 
      for (let s = divisiPerSettimana.length; s < this.settimane.length; s++) {
        this.settimane[s].next([]);
      }

      // tutto ciò al netto del fatto che, almeno in teoria, il component ha una
      // comunque subscribe su questo Observer che creo qui sotto, ovvero quello
      // che gli dice quante sono le settimane da mostrare. Se così è, sempre in
      // teoria, non dovrebbe fregargliene niente che la settimana in pià abbia
      // dati sbagliati, tanto non la mostra.
      if (this.numeroSettimane == null) {
        this.numeroSettimane = new BehaviorSubject<number>(divisiPerSettimana.length);
      } else {
        this.numeroSettimane.next(divisiPerSettimana.length);
      }

    }

  }

  private static splitWeeks(giorni: Array<ICGiorno>): Array<ICGiorno[]> {
    const result = Array<ICGiorno[]>();
    let curWeek = -1;
    let weekArr: Array<ICGiorno>;

    for (let i = 0; i < giorni.length; i++) {
      const gWeek = giorni[i].settimana;
      if (gWeek != curWeek) {
        weekArr = Array<ICGiorno>();
        result.push(weekArr);
        curWeek = gWeek;
      }
      weekArr.push(giorni[i]);
    }
    return result;
  }

  public setMese(oggi: number = 0, tzstr: string = 'Asia/Dubai'): void {
    
    this.initDefaultStartDate();

    dayjs.extend(utc);
    dayjs.extend(timezone);
    if (oggi < 10000) { // interpreto l'argomento come numero di mesi positivo o negativo
      oggi = this.startDate.add(oggi, 'month').valueOf();
      } else {
        if (oggi === 0) {  // se mi passano 0 prendo il mese attuale
          oggi = dayjs().tz(tzstr).valueOf();
        } else { // in tutti gli altri casi prendo l'argomento come numero di millis dal 1970
          // e di conseguenza non faccio nulla, perché è già a posto così.
        }
    }

    const djso = dayjs(oggi);
    const tzdo = djso.tz(tzstr);
    const sdo = tzdo.startOf('month');

    this.cs_next(sdo);

  }

  public getGiornoSelezionato(): Observable<ICGiorno> {
    return this.giornoSelezionato;
  }

  public setGiornoSelezionato(giorno: ICGiorno): void {
    this.giornoSelezionato.next(giorno);
  }

  public segnaleOrarioIso8601(): Observable<string> {
    return this.segnaleOrario;
  }

  public mancanoMenoDiXOreA(iso8601: string, x: number): Observable<boolean> {
    return this.segnaleOrarioIso8601().pipe(map(iso => dayjs(iso8601).diff(iso, 'hour') < x ));
  }

  public mancanoMenoDi24OreA(iso8601: string): Observable<boolean> {
    return this.mancanoMenoDiXOreA(iso8601, 24);
  }
}
