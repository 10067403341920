import { Uscita } from './../../api/uscita.service';
import { Ospite } from './../../api/ospite.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as dayjs from 'dayjs';
import { OspiteService } from '../../api/ospite.service';

@Component({
  selector: 'ic-calendar-guest-modal',
  templateUrl: './ic-calendar-guest-modal.component.html',
  styleUrls: ['./ic-calendar-guest-modal.component.scss']
})
export class IcCalendarGuestModalComponent implements OnInit {

  @Input() uscita: Uscita;
  @Input() ospite: Ospite;

  editMode: boolean;

  createGuestForm: FormGroup;
  skills = [];
  avvisominorenne: boolean = false;

  constructor(
    private modalController: ModalController,
    public ospSvc: OspiteService
  ) { }

  ngOnInit() {
    if (!this.ospite) {
      this.ospite = new Ospite();
      this.ospite.nome = '';
      this.ospite.cognome = '';
      this.ospite.email = '';
      this.ospite.telefono = '';
      this.ospite.nascita = null;
      this.ospite.id_uscita = this.uscita.id;
      this.ospite.minorenne = false;
      this.ospite.amico_parente_accompagnatore = '';
      this.editMode = false;
    }
    else {
      this.editMode = true;
    }
    
    this.createGuestForm = new FormGroup({
      nome: new FormControl(this.ospite.nome),
      cognome: new FormControl(this.ospite.cognome, Validators.required),
      email: new FormControl(this.ospite.email, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')),
      /*
      email: new FormControl('', Validators.compose([
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        Validators.required
      ])), */
      telefono: new FormControl(this.ospite.telefono),
      nascita: new FormControl(this.ospite.nascita),
      minorenne: new FormControl(this.ospite.minorenne),
      amico_parente_accompagnatore: new FormControl(this.ospite.amico_parente_accompagnatore)
    });

  }

  dismissModal() {
   this.modalController.dismiss();
  }

  private bindData() {
    this.ospite.nome = this.createGuestForm.value.nome;
    this.ospite.cognome = this.createGuestForm.value.cognome;
    this.ospite.nascita = dayjs(this.createGuestForm.value.nascita).unix(); // save it in timestamp
    this.ospite.telefono = this.createGuestForm.value.telefono;
    this.ospite.email = this.createGuestForm.value.email;
    this.ospite.amico_parente_accompagnatore = this.createGuestForm.value.amico_parente_accompagnatore;
    this.ospite.minorenne = this.createGuestForm.value.minorenne;
  }

  createGuest() {
    this.bindData();

    this.ospSvc.create(this.ospite, this.uscita)
    .then(() => {
      this.dismissModal();
    });
  }

  updateGuest() {
    this.bindData();

    this.ospSvc.update(this.ospite, this.uscita)
    .then(() => {
      this.dismissModal();
    });
  }

  removeGuest() {
    this.ospSvc.delete(this.ospite, this.uscita).then(() => {
      this.dismissModal();
    });
  }

  minorenneChange(event) {
    this.avvisominorenne = event.detail.checked;
  }
}











