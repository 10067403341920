<!-- This is the proper way if you have submit button outside your ion-content form (typically in the ion-header or ion-footer) -->
<!-- (ref: https://github.com/ionic-team/ionic/issues/16661) -->
<form class="input-text-form ion-page" [formGroup]="inputTextForm">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="end">
          <ion-button (click)="dismissModal()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{title}}</ion-title>
      </ion-toolbar>
    </ion-header>
  
    <ion-content class="create-user-content">
  
      <section class="fields-section">
        <ion-list class="inputs-list wrapper" lines="full">
          <ion-item class="input-item">
            <ion-label color="secondary">{{text_label}}</ion-label>
          </ion-item>
          <ion-item class="input-item">
            <ion-textarea type="text" cols="30" rows="10" formControlName="testo"></ion-textarea>
          </ion-item>
          <ion-item class="input-item">
            <p class="hints">{{hints}}</p>
          </ion-item>
        </ion-list>
      </section>
  
    </ion-content>
  
    <ion-footer>
      <ion-row class="form-actions-wrapper">
        <ion-col>
          <ion-button (click)="dismissOk()" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid" 
          [disabled]="!inputTextForm.valid">
          {{ok_button_label}}</ion-button>
        </ion-col>
        <ion-col>
            <ion-button (click)="dismissCancel()" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid">
            {{cancel_button_label}}</ion-button>
          </ion-col>
      </ion-row>
    </ion-footer>
  </form>
  