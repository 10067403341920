import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShellModule } from '../shell/shell.module';

import { CheckboxWrapperComponent } from './checkbox-wrapper/checkbox-wrapper.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { RatingInputComponent } from './rating-input/rating-input.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { IcCalendarComponent } from './ic-calendar/ic-calendar.component';
import { IcCalendarMonthComponent } from './ic-calendar-month/ic-calendar-month.component';
import { IcCalendarWeekComponent } from './ic-calendar-week/ic-calendar-week.component';
import { IcCalendarDayComponent } from './ic-calendar-day/ic-calendar-day.component';
import { IcCalendarMarkerComponent } from './ic-calendar-marker/ic-calendar-marker.component';
import { IcCalendarDetailComponent } from './ic-calendar-detail/ic-calendar-detail.component';
import { IcCalendarEventslistComponent } from './ic-calendar-eventslist/ic-calendar-eventslist.component';
import { IcCalendarEventComponent } from './ic-calendar-event/ic-calendar-event.component';
import { IcCalendarEventPositionComponent } from './ic-calendar-event-position/ic-calendar-event-position.component';
import { IcCalendarEposUserComponent } from './ic-calendar-epos-user/ic-calendar-epos-user.component';
import { IcCalendarGuestsComponent } from './ic-calendar-guests/ic-calendar-guests.component';
import { IcCalendarGuestModalComponent } from './ic-calendar-guest-modal/ic-calendar-guest-modal.component';
import { TechlogModalComponent } from './techlog-modal/techlog-modal.component';
import { InputTextModalComponent } from './input-text-modal/input-text-modal.component';
import { OutstandingProblemsComponent } from './outstanding-problems/outstanding-problems.component';
import { ProblemsHistoryComponent } from './problems-history/problems-history.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ShellModule,
    IonicModule,
    ReactiveFormsModule
  ],
  declarations: [
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    IcCalendarComponent,
    IcCalendarMonthComponent,
    IcCalendarWeekComponent,
    IcCalendarDayComponent,
    IcCalendarMarkerComponent,
    IcCalendarDetailComponent,
    IcCalendarEventslistComponent,
    IcCalendarEventComponent,
    IcCalendarEventPositionComponent,
    IcCalendarEposUserComponent,
    IcCalendarGuestsComponent,
    IcCalendarGuestModalComponent,
    TechlogModalComponent,
    InputTextModalComponent,
    OutstandingProblemsComponent,
    ProblemsHistoryComponent
  ],
  exports: [
    ShellModule,
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    IcCalendarComponent,
    IcCalendarMonthComponent,
    IcCalendarWeekComponent,
    IcCalendarDayComponent,
    IcCalendarMarkerComponent,
    IcCalendarDetailComponent,
    IcCalendarEventslistComponent,
    IcCalendarEventComponent,
    IcCalendarEventPositionComponent,
    IcCalendarEposUserComponent,
    IcCalendarGuestsComponent,
    IcCalendarGuestModalComponent,
    TechlogModalComponent,
    InputTextModalComponent,
    OutstandingProblemsComponent,
    ProblemsHistoryComponent
  ]
})
export class ComponentsModule {}
