// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBsbM44FoZQ4_Zteb4TAvsOT-7DFJkVbZQ',
    authDomain: 'italian-choice.firebaseapp.com',
    projectId: 'italian-choice',
    storageBucket: 'italian-choice.appspot.com',
    messagingSenderId: '921256129832',
    appId: '1:921256129832:web:67e18f709d664877d64c1d',
    measurementId: 'G-8HWY4ZLBV9'

  },
  appShellConfig: {
    debug: false,
    networkDelay: 0
  },
  serviceAccount: {
    username: 'support@draculapp.com',
    password: 'NonCambiareMAIQuestaPassword!'
  }
};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
