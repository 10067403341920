import { Injectable } from '@angular/core';
import { Uscita, UscitaService } from './uscita.service';
import { FirebaseService } from './firebase.service';
import { Observable } from 'rxjs';
import { WhereFilterOp } from '@firebase/firestore-types';



export class Ospite {
  public id: string;
  public id_uscita: string;
  public cognome: string;
  public nome: string;
  public telefono: string;
  public email: string;
  public nascita: number; // unix timestamp
  public minorenne: boolean;
  public amico_parente_accompagnatore: string;

}


@Injectable({
  providedIn: 'root'
})
export class OspiteService {

  constructor(private fbs: FirebaseService,
              private uSvc: UscitaService) { 

  }

  public getFirebaseCollectionPath(uscita: Uscita): string {
    return this.uSvc.getFirebasePathAndPK(uscita) + '/ospiti';
  }

  public getFirebasePathAndPK(uscita: Uscita, osp: Ospite): string {
    return this.getFirebaseCollectionPath(uscita) + '/' + osp.id;
  }

  public create(pp: Ospite, uscita: Uscita): Promise<Ospite>  {
    return this.fbs.create(pp, this.getFirebaseCollectionPath(uscita), null);
  }

  public get(uscita: Uscita, id: string): Observable<Ospite> {
    return this.fbs.get(this.getFirebaseCollectionPath(uscita), id, () => new Ospite());
  }

  public getList(uscita: Uscita): Observable<Ospite[]> {
    return this.fbs.getList(this.getFirebaseCollectionPath(uscita), () => new Ospite());
  }

  public update(osp: Ospite, uscita: Uscita): Promise<any> {
    return this.fbs.update(osp, this.getFirebaseCollectionPath(uscita), osp.id);
  }

  public delete(osp: Ospite, uscita: Uscita): Promise<any> {
    return this.fbs.delete(this.getFirebaseCollectionPath(uscita), osp.id);
  }

}
