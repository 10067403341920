import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService, Utente } from '../../api/user.service';
import { PosizionePrenotata, PosizionePrenotataService } from '../../api/posizione-prenotata.service';
import { Uscita, UscitaService } from '../../api/uscita.service';
import { FirebaseService } from '../../api/firebase.service';
import { ModalController, IonRouterOutlet } from '@ionic/angular';
import { InputTextModalComponent } from '../input-text-modal/input-text-modal.component';
import { CalendarOptions, ICalendar, ICSAlarm } from 'datebook';
import { MezzoDiTrasportoService } from '../../api/mezzo-di-trasporto.service';

@Component({
  selector: 'ic-calendar-epos-user',
  templateUrl: './ic-calendar-epos-user.component.html',
  styleUrls: ['./ic-calendar-epos-user.component.scss']
})
export class IcCalendarEposUserComponent implements OnInit {

  @Input() id_utente: string;
  @Input() posizione: PosizionePrenotata;
  @Input() uscita: Uscita;

  public utente$: Observable<Utente>;
  public canRedirect$: Observable<boolean>;
  public mostraLibera$: Observable<boolean>;

  constructor(private userSvc: UserService,
              private ppSvc: PosizionePrenotataService,
              private fbSvc: FirebaseService,
              private uSvc: UscitaService,
              private mdtSvc: MezzoDiTrasportoService,
              private modalController: ModalController,
              private routerOutlet: IonRouterOutlet) { }

  ngOnInit(): void {
    this.utente$ = this.userSvc.get(this.id_utente);
    this.canRedirect$ = this.userSvc.canRedirect(this.fbSvc.getUserId());
    this.mostraLibera$ = new Observable<boolean>(sub => sub.next(this.id_utente === this.fbSvc.getUserId()));
  }

  liberaPosizione(u: Utente) {
    if (this.uscita.id_utente_master == this.fbSvc.getUserId()) {
      // Il master deve inserire la motivazione
      this.chiediMotivazione(u);
    } else {
      this.ppSvc.delete(this.posizione, this.uscita);
    }
  }

  esportaICS() {
    const mezzo = this.mdtSvc.getMezzoDaID(this.uscita.idMezzo);
    const config: CalendarOptions = {
      title: this.posizione.denominazione,
      location: 'Italian Choice',
      description: this.posizione.denominazione + ' a bordo della ' + mezzo.nome,
      start: new Date(this.uscita.iso8601),
      end: new Date(this.uscita.iso8601rientro),
    }
    
    const icalendar = new ICalendar(config);
    
    icalendar.addAlarm({
      description: 'Italian Choice',
      action: 'DISPLAY',
      trigger: {
        days: 2
      }
    });

    icalendar.download();

  }

  ruoloMaster(): string {
    if (this.id_utente === this.uscita.id_utente_master) {
      return '(Master)';
    }
    return '';
  }

  async chiediMotivazione(u: Utente) {
    const modal = await this.modalController.create({
      component: InputTextModalComponent,
      componentProps: {
        'title': 'Motivazione',
        'text': this.uscita.motivo_annullamento,
        'text_label': 'Motivo della rinuncia',
        'hints': 'Se presenti, lasciare inalterati eventuali motivi preesistenti ed aggiungere il proprio', 
        'ok_button_label': 'Cancellami',
        'cancel_button_label': 'Annulla'
      },
      swipeToClose: false,
      presentingElement: this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((motivazione) => {
      if (motivazione != null && motivazione.data !== null) {
        this.uscita.motivo_annullamento = motivazione.data + ' (' + u.nome + ' ' + u.cognome + ')';
        this.uscita.id_utente_master = ''; // il master si è cancellato dalla sua posizione a bordo,
        this.uSvc.update(this.uscita).then(val => { // quindi smette anche di essere master
          console.log("L'uscita " + this.uscita.id + " è ora senza master");
          this.ppSvc.delete(this.posizione, this.uscita);
        }).catch(err => {
          console.log("Impossibile rimuovere il master dall'uscita " + this.uscita.id);
          console.error(err);
        }); 
      }
    });

    return await modal.present();
  }
}
