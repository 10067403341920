<form class="problems-form ion-page" [formGroup]="problemiForm">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="end">
          <ion-button (click)="dismissModal()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Intervento o soluzione</ion-title>
      </ion-toolbar>
    </ion-header>
  
    <ion-content [scrollEvents]="true">
        <section id="form">
          <ion-list class="inputs-list" lines="full">
            <span id="risolti_il"></span>
            <ion-item class="input-item">
              <ion-label color="secondary" position="floating">Intervento effettuato il</ion-label>
              <ion-datetime display-timezone="Asia/Dubai" placeholder="Oggi di default" formControlName="fc_risolti_il" displayFormat="YYYY-MM-DD"></ion-datetime>
            </ion-item>
            <span id="risolti_da"></span>
            <ion-item class="input-item">
              <ion-label color="secondary" position="floating">Da</ion-label>
              <ion-input placeholder="Di default sarà il tuo nome" formControlName="fc_risolti_da"></ion-input>
            </ion-item>
            <span id="risolti_con"></span>
            <ion-item class="input-item">
              <ion-label color="secondary" position="floating">Descrizione intervento o soluzione</ion-label>
              <ion-textarea autofocus="true" minlength="2" required="true" inputmode="text" placeholder="Descrivi l'intervento effettuato e/o la soluzione adottata" rows="7" cols="20" type="text" formControlName="fc_risolti_con"></ion-textarea>
            </ion-item>
            <span id="mantieni_aperto"></span>
            <ion-item class="input-item">
              <ion-label color="secondary" position="floating">Non ancora risolto</ion-label>
              <ion-checkbox (ionChange)="checkChanged($event)" formControlName="fc_mantieni_ticket_aperto"></ion-checkbox>
            </ion-item>
          </ion-list>
        </section>
    </ion-content>
  
    <ion-footer>
      <ion-row class="form-actions-wrapper">
        <ion-col>
            <ion-button (click)="update()" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid">
            {{testoTastoSalva}}</ion-button>
        </ion-col>
      </ion-row>
    </ion-footer>
  </form>
  