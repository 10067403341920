<div class="ic-calendar-wrapper">
  <div class="ic-calendar" *ngIf="giorni$ | async as giorni">
    <div class="ic-calendar-head">
      <ion-button class="ic-calendar-prev" (click)="mostraMese(-1)">◄</ion-button>
      <div class="ic-calendar-monthname">{{nomeMese(giorni)}}</div>
        <div class="ic-calendar-yearnumber">
          <div class="ic-calendar-year-wrapper">
          <ion-button [hidden]="hideYearControls" (click)="mostraMese(12)">▲</ion-button>
          <ion-button fill="outline" (click)="mostraControlliAnno()">{{numeroAnno(giorni)}}</ion-button>
          <ion-button [hidden]="hideYearControls" (click)="mostraMese(-12)">▼</ion-button>
        </div>
      </div>
      <ion-button class="ic-calendar-next" (click)="mostraMese(1)">►</ion-button>
    </div>
    <div class="ic-calendar-title"></div>
    <div class="ic-calendar-dayslabels">
      <div *ngFor="let idx of [0,1,2,3,4,5,6]" class="ic-calendar-daylabel">{{nomeGiorno(giorni[idx])}}</div>
   </div>
    <ic-calendar-month></ic-calendar-month>
  </div>
  <ic-calendar-detail></ic-calendar-detail>
  <ic-calendar-eventslist></ic-calendar-eventslist>
</div>
