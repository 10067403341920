import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Uscita } from '../../api/uscita.service';
import { UserService } from '../../api/user.service';
import { FirebaseService } from '../../api/firebase.service';
import { map, share } from 'rxjs/operators';
import { PosizioneSulMezzo } from '../../api/posizione-sul-mezzo.service';
import { PosizionePrenotata } from '../../api/posizione-prenotata.service';
import { PosizionePrenotataService } from '../../api/posizione-prenotata.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OspiteService } from '../../api/ospite.service';
import { MezzoDiTrasportoService, MDTConfigService, MDTConfig } from '../../api/mezzo-di-trasporto.service';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
  selector: 'ic-calendar-event-position',
  templateUrl: './ic-calendar-event-position.component.html',
  styleUrls: ['./ic-calendar-event-position.component.scss']
})
export class IcCalendarEventPositionComponent implements OnInit {

  @Input() uscita: Uscita;
  @Input() posizione: PosizioneSulMezzo;
  @Input() mdtConfigs: MDTConfig[];


  public posizioneOccupata$: Observable<PosizionePrenotata>;
  public occupabile$: Observable<boolean>;
  public giaABordo$: Observable<boolean>;
  public posti$: Subject<number> = new Subject<number>();

  constructor(
              private fbSvc: FirebaseService,
              private ppSvc: PosizionePrenotataService,
              private userSvc: UserService,
              private ospSvc: OspiteService,
              private mdtSvc: MezzoDiTrasportoService,
              private mdtcSvc: MDTConfigService) { }

  ngOnInit(): void {
    this.posizioneOccupata$ = this.ppSvc.get(this.uscita, this.posizione.denominazione.toLowerCase());
    this.occupabile$ = this.userSvc.canRedirect(this.fbSvc.getUserId()).pipe(map(canRedir => (canRedir || !this.uscita.annullata) && dayjs(this.uscita.iso8601).valueOf() > dayjs().valueOf()));
    this.giaABordo$ = this.ppSvc.getListByIdUtente(this.uscita, this.fbSvc.getUserId()).pipe(map(posarr => (posarr && posarr.length > 0)));
    this.ppSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(
      prenotazioni => {
        this.ospSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(
          ospiti => {
            const mezzo = this.mdtSvc.getMezzoDaID(this.uscita.idMezzo);
            const maxPers = this.mdtcSvc.getConfigDaID(mezzo.id, this.mdtConfigs).maxPersoneABordo;

            const postiliberi = maxPers - prenotazioni.length - ospiti.length; 
            this.posti$.next(postiliberi);
          })});
  }

  public getAboard() {
    // ogni utente può occupare una sola posizione, quindi questa tasto prima di tutto
    // toglie l'utente da altra posizione eventualmente già occupata in questa uscita
    // e poi lo mette nella posizione attualmente scelta

    const subscription = this.ppSvc.getListByIdUtente(this.uscita, this.fbSvc.getUserId()).subscribe(
      posarr => {
        for (let i = 0; i < posarr.length; i++) {
          this.ppSvc.delete(posarr[i], this.uscita).then((v: any) => {});
        }
        subscription.unsubscribe();
        const v = new PosizionePrenotata();
        Object.assign(v, {...this.posizione});
        v.id_utente = this.fbSvc.getUserId();
        this.ppSvc.create(v, this.uscita).then((result) => {
          console.log(this.uscita);
          console.log(result);
        }).catch(error => { 
          console.error(error);
        });
      }, err => {
        console.error(err);
    });
  }
}
