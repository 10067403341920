import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICGiorno, CalendarioService } from '../../api/calendario.service';

@Component({
  selector: 'ic-calendar-week',
  templateUrl: './ic-calendar-week.component.html',
  styleUrls: ['./ic-calendar-week.component.scss'],
})
export class IcCalendarWeekComponent implements OnInit {
  
  public days$: Observable<ICGiorno[]>;

  @Input() numero: number;

  constructor(private calSrv: CalendarioService) {

   }

  ngOnInit() {
    this.days$ = this.calSrv.getGiorniSettimana(this.numero);
  }


}
