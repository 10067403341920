
<div class="posizione">{{posizione.denominazione}}</div>
<div *ngIf="posizioneOccupata$ | async as pos; else available">
    <ic-calendar-epos-user [id_utente]="pos.id_utente" [uscita]="this.uscita" [posizione]="pos"></ic-calendar-epos-user>
</div>
<ng-template #available>
    <div *ngIf="giaABordo$ | async as gab; else occupabile">
        <ion-button class="swtichposition" (click)="getAboard()">Spostami qui</ion-button>
    </div>
    <ng-template #occupabile>
        <div *ngIf="occupabile$ | async as occ">
            <ion-button *ngIf="{val: posti$ | async} as posti" [disabled]="posti.val <= 0" class="getaboard" (click)="getAboard()">Occupa</ion-button>
        </div>
    </ng-template>
</ng-template>
