import { MDTConfigService } from './../../api/mezzo-di-trasporto.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UscitaService, Uscita } from '../../api/uscita.service';
import { CalendarioService, ICGiorno } from '../../api/calendario.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MDTConfig } from '../../api/mezzo-di-trasporto.service';
import { map } from 'rxjs/operators';
import { PosizionePrenotataService } from '../../api/posizione-prenotata.service';

@UntilDestroy()
@Component({
  selector: 'ic-calendar-eventslist',
  templateUrl: './ic-calendar-eventslist.component.html',
  styleUrls: ['./ic-calendar-eventslist.component.scss']
})
export class IcCalendarEventslistComponent implements OnInit {

  public uscite$: Observable<Uscita[]>;
  public mezziConf$: Observable<MDTConfig[]>;

  constructor(private uSvc: UscitaService,
              private calSvc: CalendarioService,
              private ppSvc: PosizionePrenotataService,
              private mdtcSvc: MDTConfigService) {
  }

  ngOnInit(): void {
    this.calSvc.getGiornoSelezionato().pipe(untilDestroyed(this)).subscribe((giorno: ICGiorno) => {
      this.uscite$ = this.uSvc.getListForDay(giorno.iso8601).pipe(map(uscite => uscite.sort(this.criterioOrdinamentoUscite)));
    });
    this.mezziConf$ = this.mdtcSvc.getList();
    const subscription = this.mezziConf$.subscribe(confs => {
      for (let i = 0; i < confs.length; i++) {
        console.log(confs[i].id + ' ' + confs[i].maxPersoneABordo);
      }
      subscription.unsubscribe();
    });
  }

  public criterioOrdinamentoUscite(a: Uscita, b: Uscita): number {
    // TODO: qui si dovrebbe far apparire prima le uscite gialle, poi quelle verdi, poi quelle blu 
    // ed a seguire tutte le altre.
    // Il problema è che la this.ppSvc.getStatoUscita() ha bisogno di informazioni che qui non
    // abbiamo e che dovremmo recuperare dal database. Queste, se dovessimo leggerle qui, 
    // rallenterebbero abbastanza le operazioni e genererebbero un certo numero di letture da db.
    // Per ora ritorno 0. La soluzione migliore sarebbe salvare lo stato dell'uscita man mano
    // che lo si calcola, in modo da averlo già qui disponibile e poterlo usare. Il problema
    // di questo approccio è il rischio che lo stato calcolato e salvato dell'uscita non sia poi
    // sincronizzato con lo stato reale, anche solo per problemi di connettività fra quando si 
    // aggiunge un ospite o un velista e quando si salva lo stato dell'uscita.
    // A fronte di tutto ciò ne parleremo con il cliente e decideremo come procedere.

    // Con il cliente abbiamo deciso di non implementare questo ordinamento.

    // 2023-01-17: In alternativa mostriamo le uscite in ordine di orario
    return a.iso8601.localeCompare(b.iso8601);
  }


}
