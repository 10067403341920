import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICGiorno, CalendarioService } from '../../api/calendario.service';

@Component({
  selector: 'ic-calendar-month',
  templateUrl: './ic-calendar-month.component.html',
  styleUrls: ['./ic-calendar-month.component.scss'],
})
export class IcCalendarMonthComponent implements OnInit {
  
  public weeks$: Observable<number>;

  constructor(private calService: CalendarioService) { }

  ngOnInit() {
    this.weeks$ = this.calService.getSettimaneMese();
    this.calService.setMese();
  }

}
