<div class="ic-calendar-detail-wrapper" *ngIf="selectedDay$ | async as selDay">
    <div class="ic-calendar-detail-add-wrapper" *ngIf="canBook">
        <div class="ic-calenadr-detail-add-button-wrapper">
            <ion-button (click)="toggleFormUscita()">Aggiungi uscita</ion-button>
        </div>
        <div class="ic-calendar-detail-selected-day">il giorno {{selectedDay(selDay)}}</div>
    </div>

    <div class="ic-calendar-detail-form-wrapper" *ngIf="mostraForm">
        <div class="ic-calendar-detail-form-mezzi" *ngIf="mezziDisponibili$ | async as mezzi">
            <ion-list>
                <ion-radio-group [(ngModel)]="mezzo">
                  <ion-list-header>
                    <ion-label>Scegli la barca</ion-label>
                  </ion-list-header>
                  
                  <div class="ic-calendar-mezzi-wrapper"> 
                      <div class="radio-wrapper" *ngFor="let m of mezzi">
                        <ion-item class="ic-calendar-detail-form-mezzo" [style]="getStyle(m)" (click)="forwardClick(m)">
                            <ion-label class="mezzo-label">{{m.nome}}</ion-label>
                            <ion-radio [id]="m.id" slot="start" [value]="m.id" class="mezzo-radio" [disabled]="!mezzoPrenotabile(m)"></ion-radio>
                        </ion-item>
                        <ion-button (click)="showProblems(m.id)">Problemi aperti</ion-button>                        
                    </div>
                  </div>

                </ion-radio-group>
                <ion-item *ngIf="showMaintenance">
                    <ion-label>Metti in manutenzione</ion-label>
                    <ion-checkbox [(ngModel)]="manutenzione" (click)="manutClick()"></ion-checkbox>
                </ion-item>
                <ion-item>
                    <ion-label>Imposta l'orario di uscita</ion-label>
                    <ion-datetime display-timezone="Asia/Dubai" [(ngModel)]="orario" displayFormat="HH:mm" minuteValues="0,15,30,45"></ion-datetime>
                </ion-item>
                <ion-item>
                    <ion-label>Imposta l'orario di rientro</ion-label>
                    <ion-datetime display-timezone="Asia/Dubai" [(ngModel)]="orariorientro" displayFormat="HH:mm" minuteValues="0,15,30,45"></ion-datetime>
                </ion-item>
                <ion-item fill="outline">
                    <ion-label>TARGET</ion-label>
                    <ion-input [(ngModel)]="target" maxlength="30" class="targetinput" placeholder="Obbligatorio"></ion-input>
                </ion-item>
            </ion-list>

            <div class="ic-calendar-detail-actions-wrapper">
                <div class="ic-calenadr-detail-action-cancel">
                    <ion-button (click)="toggleFormUscita()">Annulla</ion-button>
                </div>
                <div class="ic-calenadr-detail-action-confirm">
                    <ion-button (click)="salva(selDay)">Conferma</ion-button>
                </div>
            </div>          
        </div>                 
    </div>
</div>
