import { Injectable } from '@angular/core';

export class TipologiaMezzo {

  constructor(tipo: string = null) {
    this.nome = tipo;
  }
  public nome: string;
}


@Injectable({
  providedIn: 'root'
})
export class TipologiaMezzoService {

  public static barcaAVela = new TipologiaMezzo("Barca a vela");

  constructor() { }
}
