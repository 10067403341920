import { MDTConfig } from './../../api/mezzo-di-trasporto.service';
import { EventEmitter, Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Uscita } from '../../api/uscita.service';
import { UscitaService } from '../../api/uscita.service';
import { ICGiorno, CalendarioService } from '../../api/calendario.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MDTConfigService } from '../../api/mezzo-di-trasporto.service';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';


@UntilDestroy()
@Component({
  selector: 'ic-calendar-day',
  templateUrl: './ic-calendar-day.component.html',
  styleUrls: ['./ic-calendar-day.component.scss'],
})
export class IcCalendarDayComponent implements OnInit {

  public markers$: Observable<Uscita[]>;
  public mezziConf$: Observable<MDTConfig[]>;

  private inMonthClass: string;
  private selectedClass: string;
  public cssClass: string;
  public todayCss: string;
  public selected: boolean;
  private giornoSelezionato: Observable<ICGiorno>;
  

  @Input() datiGiorno: ICGiorno;

  @Output() dettagli = new EventEmitter();

  constructor(private uSvc: UscitaService,
              private calSvc: CalendarioService,
              private mdtcSvc: MDTConfigService) { }

  ngOnInit() {
    this.markers$ = this.uSvc.getListForDay(this.datiGiorno.iso8601);
    this.mezziConf$ = this.mdtcSvc.getList();
    this.inMonthClass = this.datiGiorno.parteDelMese ? 'ic-calendar-day-in-month' : 'ic-calendar-martian-day';
    this.selectedClass = '';
    this.giornoSelezionato = this.calSvc.getGiornoSelezionato();
    this.cssClass = this.inMonthClass + this.selectedClass;
    this.giornoSelezionato.pipe(untilDestroyed(this)).subscribe(observer => {
      if (observer.iso8601 === this.datiGiorno.iso8601) {
        this.selectedClass = ' ic-calendar-selected-day';
      } else {
        this.selectedClass = '';
      }
      this.cssClass = this.inMonthClass + this.selectedClass;
    });
    dayjs.extend(utc);
    dayjs.extend(timezone);               
    this.setTodayCss(dayjs().tz('Asia/Dubai').format());
     
    this.todayCss = '';
    this.calSvc.segnaleOrarioIso8601().pipe(untilDestroyed(this)).subscribe(timetick => {
      this.setTodayCss(timetick);
    });
  }

  private setTodayCss(iso8601: string) {
    const questo = dayjs(this.datiGiorno.iso8601).tz("Asia/Dubai");
    const adesso = dayjs(iso8601).tz("Asia/Dubai");
    const qday = questo.startOf('day').format("YYYYMMDD");
    const aday = adesso.startOf('day').format("YYYYMMDD");
    this.todayCss = (qday === aday) ? 'color: darkorange; background-color: black;' : '';
  }

  public mostraDettaglio() {
    this.calSvc.setGiornoSelezionato(this.datiGiorno);
    this.dettagli.emit();
  }


}
