import { Injectable } from '@angular/core';
import { FirebaseAuthService } from '../firebase/auth/firebase-auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WhereFilterOp } from '@firebase/firestore-types';
import { AngularFirestore, DocumentData, Query } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';


export interface FbEntity {
  id: string;
  [propName: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  
  private loggedIn: boolean;

  public previousUrl;

  public static ENTITY_ALREADY_EXISTS = "Entity already exists";

  constructor(private faService: FirebaseAuthService,
              private afs: AngularFirestore,
              private afa: AngularFireAnalytics) { }

  public getAfs(): Promise<AngularFirestore> {
    return new Promise<AngularFirestore>((resolve, reject) => {
      if (this.isLoggedIn()) {
        resolve(this.afs);
      } else {
        reject(false);
      }
    });
  }

  private loginIfNeeded(email: string, password: string): Promise<AngularFirestore> {

    const result = new Promise<AngularFirestore>((resolve, errmanager) => {
      if (!this.loggedIn) {
    
        this.faService.signInWithEmail(email, password).
            then(_res => {
              this.loggedIn = true;
              this.afa.logEvent('login', { email: email });
              resolve(this.afs);
            }, 
            rej => {
              errmanager('no access');
            });
        } else {
          resolve(this.afs);
        }
    
    });
    return result;
  }

  public login(email: string, password: string): Promise<AngularFirestore> {
    this.loggedIn = false;
    return this.loginIfNeeded(email, password);
  }

  public logout(): Promise<any> {
    this.loggedIn = false;
    return this.faService.signOut().toPromise();
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

    /** 
   * Initiate the password reset process for this user 
   * @param email email of the user 
   */ 
  public initPasswordReset(email: string): Promise<void> {
    let portpart = "";

    if (typeof window.location.port !== 'undefined' && window.location.port) {
      portpart = ":" + window.location.port;
    };

    const returnToUrl = window.location.protocol + '//' + window.location.hostname + portpart + '/auth/login';
    return this.faService.angularFire.sendPasswordResetEmail(
        email, 
        { url: returnToUrl}
        ); 
  }

  public getUserId(): string {
    if (this.faService.currentUser && this.isLoggedIn()) {
      return this.faService.currentUser.uid;
    }
  }

  public convertToJSObject<TSC extends FbEntity>(tsObject: TSC): Object {
    const jsObject: Object = {...tsObject};
    delete jsObject['id'];
    return jsObject;
  }
  
  public convertToTSClassInstance<TSC extends FbEntity>(fbObject: any, result: TSC): TSC {
    if (typeof fbObject !== 'undefined') {
      if (typeof fbObject.payload !== 'undefined' && typeof fbObject.payload.doc !== 'undefined') {
        const srcobj = fbObject.payload.doc.data();
        Object.assign(result, srcobj);
        result.id = fbObject.payload.doc.id;
      } else {
        Object.assign(result, fbObject);
      }
      return result;
    }
  }

  public create<TSC extends FbEntity>(entity: TSC, path: string, pk: string = null, analyticsEvent: string = null, analyticsData = null): Promise<TSC>  {
    return new Promise<TSC>((resolve, reject) => {
      if (pk == null) {
        pk = this.afs.createId();
      }
      const docRef = this.afs.collection(path)
                       .doc(pk).ref;
        this.afs.firestore.runTransaction(tx => {
          return tx.get(docRef)
            .then((document) => {
                if (document.exists) {
                  reject(FirebaseService.ENTITY_ALREADY_EXISTS);
                }
                tx.set(docRef, this.convertToJSObject(entity));
                entity.id = pk;
                if (analyticsEvent) {
                  this.afa.logEvent(analyticsEvent, analyticsData);
                }
                resolve(entity);
                })
            .catch((err) => {
              reject(err);
            });
        })
        .then(_txres => resolve(entity))
        .catch(fbErr => reject(fbErr)
        );
      });
  }

  public get<TSC extends FbEntity>(path: string, pk: string, factory: (() => TSC)): Observable<TSC> {
    return this.afs.doc<any>(path + '/' + pk)
          .valueChanges().pipe(map(item => this.convertToTSClassInstance(item, factory())));    
  }
  
  public getList<TSC extends FbEntity>(path: string, factory: (() => TSC)): Observable<TSC[]> {
    return this.afs.collection<any>(path)
          .snapshotChanges().pipe(map(data => {
                    return data.map(item => this.convertToTSClassInstance(item, factory()));
                  }));
  }

  public getListWhere<TSC extends FbEntity>(path: string, where: Array<[string,WhereFilterOp,any]>, factory: (() => TSC), p_orderby: string = null, orderAscending: boolean = true, limit: number = 0): Observable<TSC[]> {
    return this.afs.collection<any>(path, ref => {
      let res: Query<DocumentData> = ref;
      for (let i = 0; i < where.length; i++) {
        const arg = where[i];
        if (typeof arg[2] === 'string') {
          res = res.where(...arg);
        } else {
          const list = arg[2];
          res = res.where(arg[0], arg[1], list);
        }
      }

      if (p_orderby) {
        const a_orderby = p_orderby.split(',');
        for (let o in a_orderby) {
          if (a_orderby[o]) {
            res = res.orderBy(a_orderby[o], orderAscending ? 'asc' : 'desc');
          }
        }
      }

      if (limit > 0) {
        res = res.limit(limit);
      }

      return res;
      }).snapshotChanges().pipe(map(data => {
                    return data.map(item => this.convertToTSClassInstance(item, factory()));
                  }));
  }


  public update<TSC extends FbEntity>(entity: TSC, path: string, pk: string = null, analyticsEvent: string = null, analyticsData = null): Promise<any> {
    if (pk === null) {
      pk = entity.id;
    }
    return new Promise((res, rej) => {
      this.afs.collection(path)
              .doc(pk)
              .update(this.convertToJSObject(entity))
              .then(ok => {
                if (analyticsEvent) {
                  this.afa.logEvent(analyticsEvent, analyticsData);
                }
                res(ok);
              }, ko => {
                rej(ko);                
              })
              .catch(ko => rej(ko));
    });     
  }

  public deleteEntity<TSC extends FbEntity>(path: string, entity: TSC, analyticsEvent: string = null, analyticsData = null) {
    return this.delete(path, entity.id, analyticsEvent, analyticsData);
  }

  public delete(path: string, pk: string, analyticsEvent: string = null, analyticsData = null): Promise<any> {
    return new Promise((res, rej) => {
      this.afs.collection(path)
              .doc(pk)
              .delete()
              .then(ok => {
                if (analyticsEvent) {
                  this.afa.logEvent(analyticsEvent, analyticsData);
                }
                res(ok);
              }, ko => {
                rej(ko);                
              })
              .catch(ko => rej(ko));
    }); 
  }

}