<div class="ic-calendar-single-day-wrapper" (click)="mostraDettaglio();">
  <div [class]="cssClass + ' ic-calendar-single-day'">
    <div [style]="todayCss" class="ic-calendar-daynumber">{{datiGiorno.label}}</div>
    <div class="ic-calendar-daynote">{{datiGiorno.note}}</div>
    <div class="ic-calendar-daymarkers" > 
      <div class="ic-calendar-markerswrapper" *ngIf="markers$ | async as markers">
        <div class="ic-calendar-daymarkers-list" *ngIf="{val: mezziConf$ | async} as mezziConfs">
          <div *ngFor="let u of markers" class="ic-calendar-daymarker">
            <ic-calendar-marker [uscita]="u" [mdtConfigs]="mezziConfs.val"></ic-calendar-marker>
          </div>
        </div>        
      </div>
    </div>
  </div>
</div>