import { Injectable } from '@angular/core';
import { MezzoDiTrasporto } from './mezzo-di-trasporto.service';

export interface IDenominazione {
  denominazione: string;
}

export class Denominazione implements IDenominazione {
  public constructor(public denominazione: string = null) {
    this.denominazione = denominazione;
  }
}

export class RuoloSulMezzo extends Denominazione {
  constructor(denominazione: string) {
    super(denominazione);
  }
}

export class PosizioneSulMezzo extends Denominazione {
  constructor(denominazione: string) {
    super(denominazione);
  }
}


export class DenominazioneService<T extends IDenominazione> {
  private arr: Array<T>;

  constructor(...args: T[]) {
    this.arr = args;
  }

  public getArray(): Array<T> {
    return this.arr;
  }

  public getElementFromName(name: string): T {
    const esistente = this.getArray().filter(elem => elem.denominazione.toLowerCase() === name.toLowerCase());
    if (esistente.length > 0) return esistente[0];
    return undefined;
  }

  public convertFromAny(srcobj: any): T {
    return this.getElementFromName(srcobj.denominazione);
  }
}



/**
 * Per la barca a vela le posizioni sono Timoniere, Randista, Trimmer 1, Trimmer 2, Prodiere, Jolly.
 */

@Injectable({
  providedIn: 'root'
})
export class PosizioneSulMezzoService extends DenominazioneService<PosizioneSulMezzo> {

  public static timoniere = new PosizioneSulMezzo("Timoniere");
  public static randista = new PosizioneSulMezzo("Randista");
  public static trimmer1 = new PosizioneSulMezzo("Trimmer 1");
  public static trimmer2 = new PosizioneSulMezzo("Trimmer 2");
  public static prodiere = new PosizioneSulMezzo("Prodiere");
  public static jolly = new PosizioneSulMezzo("Jolly");
  constructor() {
    super(PosizioneSulMezzoService.timoniere,
          PosizioneSulMezzoService.randista,
          PosizioneSulMezzoService.trimmer1,
          PosizioneSulMezzoService.trimmer2,
          PosizioneSulMezzoService.prodiere,
          PosizioneSulMezzoService.jolly);
   }

   public getPosizionePrincipale(mezzo: MezzoDiTrasporto | string): PosizioneSulMezzo {
     return PosizioneSulMezzoService.timoniere;
   }

}
