<div class="ic-calendar-single-week-wrapper">
  <div class="ic-calendar-single-week">
    <div class="ic-calendar-days-wrapper" *ngIf="days$ | async as days">
      <div class="ic-calendar-single-week-daylist">
        <div *ngFor="let d of days" class="ic-calendar-daybox-wrapper">
          <div *ngIf="d.settimana == numero" class="ic-calendar-daybox">
            <ic-calendar-day [datiGiorno]="d"></ic-calendar-day>
          </div>
        </div>
      </div>        
    </div>
  </div>
</div>