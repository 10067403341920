import { Injectable } from '@angular/core';
import { TipologiaMezzo, TipologiaMezzoService } from './tipologia-mezzo.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { PosizioneSulMezzo, PosizioneSulMezzoService } from './posizione-sul-mezzo.service';
import { FirebaseService } from './firebase.service';

export class MDTConfig {
  public id: string;
  public maxPersoneABordo: number;
}

@Injectable({
  providedIn: 'root'
})
export class MDTConfigService {

  constructor(private fbs: FirebaseService) {}

  public getFirebaseCollectionPath(): string {
    return '/configmezzi';
  }
    
  public getFirebasePathAndPK(mdt: MDTConfig): string {
    return this.getFirebaseCollectionPath() + '/' + mdt.id;
  }
    
  public create(mdtConfig: MDTConfig): Promise<MDTConfig>  {
    return this.fbs.create(mdtConfig, this.getFirebaseCollectionPath(), mdtConfig.id);
  }
    
  public get(id: string): Observable<MDTConfig> {
    return this.fbs.get(this.getFirebaseCollectionPath(), id, () => new MDTConfig());
  }
    
  public getList(): Observable<MDTConfig[]> {
    return this.fbs.getList(this.getFirebaseCollectionPath(), () => new MDTConfig());
  }
    
  public update(mdtc: MDTConfig): Promise<any> {
    return this.fbs.update(mdtc, this.getFirebaseCollectionPath(), mdtc.id);
  }
    
  public delete(mdtc: MDTConfig): Promise<any> {
    return this.fbs.delete(this.getFirebaseCollectionPath(), mdtc.id);
  }

  public getConfigDaID(id: string, confs: MDTConfig[]): MDTConfig {
    for (let i = 0; i < confs.length; i++) {
      if (confs[i].id === id) {
        return confs[i];
      }
    }
  }

}

export class MezzoDiTrasporto {
  
  constructor(public id: string = null, 
              public tipo: TipologiaMezzo = null, 
              public immagine: string = null,
              public equipaggio: Array<PosizioneSulMezzo> = null,
              public ospiti: number = 2,
              public nome: string = id,
              public maxUscitePerGiorno: number = 2,
              public prenotabile: number = maxUscitePerGiorno,
              public maxPersoneABordo: number = 7) {
    this.id = id;
    this.tipo = tipo;
    this.immagine = immagine;
    this.equipaggio = equipaggio;
    this.ospiti = ospiti;
    this.nome = nome;
    this.maxUscitePerGiorno = maxUscitePerGiorno;
    this.prenotabile = prenotabile;
    this.maxPersoneABordo = maxPersoneABordo;
  }
}


@Injectable({
  providedIn: 'root'
})
export class MezzoDiTrasportoService {

  public equipaggioVela = Array<PosizioneSulMezzo>(PosizioneSulMezzoService.timoniere,
    PosizioneSulMezzoService.randista, 
    PosizioneSulMezzoService.trimmer1,
    PosizioneSulMezzoService.trimmer2, 
    PosizioneSulMezzoService.prodiere, 
    PosizioneSulMezzoService.jolly);

  public vela1 = new MezzoDiTrasporto("DP-2421-IC1", 
                                            TipologiaMezzoService.barcaAVela,
                                            "DP-2421-IC1.png",
                                            this.equipaggioVela,
                                            2,
                                            "IC1-DP 2421",
                                            2);

  public vela2 = new MezzoDiTrasporto("DP-3514-IC2",
                                            TipologiaMezzoService.barcaAVela,
                                            "DP-3514-IC2.png",
                                            this.equipaggioVela,
                                            2,
                                            "IC2-DP 3514",
                                            2);

  private mezzi: BehaviorSubject<MezzoDiTrasporto[]>;

  /**
   * Al momento abbiamo solo lo sport vela e 2 barche. In futuro si potranno leggere i mezzi di
   * trasporto in modo dinamico da Firebase oppure crearli qui a seconda dello sport selezionato.
   */
   constructor() { 
    this.mezzi = new BehaviorSubject<MezzoDiTrasporto[]>(this.getArrayBarcheAVela());
  }

  public getBarcheAVela(): Observable<MezzoDiTrasporto[]> {
    return this.mezzi;
  }

  public getArrayBarcheAVela(): MezzoDiTrasporto[] {
    const payload = Array();
    payload.push(this.vela1, this.vela2);
    return payload;
  }

  public getArrayMezzi(): MezzoDiTrasporto[] {
    return this.getArrayBarcheAVela(); // per ora abbiamo solo 2 barche a vela, in futuro potremmo avere altri mezzi
  }

  public getMezzi(): Observable<MezzoDiTrasporto[]> {
    return this.mezzi;
  }

  public getBarcaDaID(id: string): MezzoDiTrasporto {
    const arr = this.getArrayBarcheAVela();
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        return arr[i];
      }
    }
  }

  public getMezzoDaID(id: string): MezzoDiTrasporto {
    const arr = this.getArrayMezzi();
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        return arr[i];
      }
    }
  }


}

