import { UscitaService, Uscita } from './../../api/uscita.service';
import { Component, OnInit, Input } from '@angular/core';
import { MezzoDiTrasportoService, MezzoDiTrasporto, MDTConfig, MDTConfigService } from '../../api/mezzo-di-trasporto.service';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscriber, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { PosizionePrenotata, PosizionePrenotataService } from '../../api/posizione-prenotata.service';
import { UserService, Utente } from '../../api/user.service';
import { FirebaseService } from '../../api/firebase.service';
import { OspiteService } from '../../api/ospite.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TechlogModalComponent } from '../techlog-modal/techlog-modal.component';
import { AlertController, IonRouterOutlet, ModalController } from '@ionic/angular';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { PosizioneSulMezzoService, PosizioneSulMezzo } from '../../api/posizione-sul-mezzo.service';
import { InputTextModalComponent } from '../input-text-modal/input-text-modal.component';
         

@UntilDestroy()
@Component({
  selector: 'ic-calendar-event',
  templateUrl: './ic-calendar-event.component.html',
  styleUrls: ['./ic-calendar-event.component.scss']
})
export class IcCalendarEventComponent implements OnInit {

  @Input() uscita: Uscita;
  @Input() uscite: Uscita[];
  @Input() conf: MDTConfig;
  @Input() azioniNascoste: boolean = false;

  private uscita_per_modal: Uscita;
  private utente_attuale: Utente;
  public styleImmagineMezzo$: Observable<string>;
  public styleStatoEquipaggio$: Observable<string>;

  public redirect$: Observable<boolean>;
  public canBook$: Observable<boolean>;
  public eliminabile$: Subject<boolean> = new ReplaySubject();
  public eliminaManutenzione = true;
  public tastoAssegnamiMaster$: Observable<boolean>;
  public posti$: Subject<number> = new Subject<number>();
  public colore$: Subject<string> = new BehaviorSubject<string>("red");
  public configs$: Observable<MDTConfig[]>;

  public giorno: string;
  public ora_uscita: string;
  public ora_rientro: string;
  public nome_mezzo: string;
  public mezzo: MezzoDiTrasporto;
  public master: string;
  public motivazioneAnnullamentoVisibile: boolean;

  public deltaOrarioMinuti: number;
  public deltaRientroMinuti: number;
  public pulsanteOrarioAbilitato: boolean;
  public pulsanteRientroAbilitato: boolean;
  public testoPulsanteOrario: string;
  public testoPulsanteRientro: string;
  public pulsanteEditTargetVisibile: boolean;

  public testoTastoAnnulla: string;
  public testoTastoTechlog: string;

  constructor(public fbSvc: FirebaseService,
              private mdtSvc: MezzoDiTrasportoService,
              private mdtcSvc: MDTConfigService,
              private uSvc: UserService,
              private ppSvc: PosizionePrenotataService,
              private psmSvc: PosizioneSulMezzoService,
              private uscSvc: UscitaService,
              private ospSvc: OspiteService,
              public alertController: AlertController,
              private modalController: ModalController,
              private routerOutlet: IonRouterOutlet) { }

  ngOnInit(): void {
    this.uscita.annullata ? this.testoTastoAnnulla = "Ripristina uscita" : this.testoTastoAnnulla = "Annulla uscita";
    this.testoTastoTechlog = "Apri Tech-log";
    this.redirect$ = this.uSvc.canRedirect(this.fbSvc.getUserId());
    this.canBook$ = this.uSvc.canBook(this.fbSvc.getUserId());
    this.tastoAssegnamiMaster$ = this.canBook$.pipe(map(canBook => (canBook && this.annullabileORipristinabile())));
    this.mezzo = this.mdtSvc.getMezzoDaID(this.uscita.idMezzo);
    this.colore$.next(this.ppSvc.getColoreUscita(this.uscita, 0, 0, this.conf.maxPersoneABordo));
    this.configs$ = this.mdtcSvc.getList();
    this.pulsanteEditTargetVisibile = this.nonManut() && this.uscita.id_utente_master !== null && this.uscita.id_utente_master === this.fbSvc.getUserId(); 
    if (this.uscita.id_utente_master) {
      this.uSvc.get(this.uscita.id_utente_master).pipe(untilDestroyed(this)).subscribe(
        u => {
          this.master = u.nome + " " + u.cognome;
        }
      );
    } 
    this.styleImmagineMezzo$ = this.colore$.pipe(map(colore => {
      const styleColore = "background-color: " + colore + ";"; 
      return styleColore + " background-image: url('/assets/" + this.mezzo.immagine + "');"
    }));
    if (this.nonManut()) {
      this.styleStatoEquipaggio$ = this.colore$.pipe(map(colore => {
        return "background-color: " + colore + ";"; 
      }));
      this.ppSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(
          prenotazioni => {
            this.ospSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(
              ospiti => {
                this.eliminaManutenzione = false;
                const postiliberi = this.conf.maxPersoneABordo - prenotazioni.length - ospiti.length; 
                this.posti$.next(postiliberi);
                this.eliminabile$.next(postiliberi === this.conf.maxPersoneABordo && this.uscita.annullata);
                this.colore$.next(this.ppSvc.getColoreUscita(this.uscita, prenotazioni.length, ospiti.length, this.conf.maxPersoneABordo));
                const statoUscita = this.ppSvc.getStatoUscita(this.uscita, prenotazioni.length, ospiti.length, this.conf.maxPersoneABordo);
                this.motivazioneAnnullamentoVisibile = (statoUscita === PosizionePrenotataService.annullata || 
                    statoUscita === PosizionePrenotataService.senzaMaster ||
                    statoUscita === PosizionePrenotataService.componentiEquipaggioInferioriAlMinimo);
                if (statoUscita === PosizionePrenotataService.componentiEquipaggioInferioriAlMinimo &&
                  (!this.uscita.motivo_annullamento)) {
                  this.uscita.motivo_annullamento = 'Equipaggio insufficiente';
                }
  
              });
            }); 
    } else {
      this.styleStatoEquipaggio$ = new BehaviorSubject("background-image: url('" + 
      "/assets/maintenance-soft.png" +
      "'); background-repeat: repeat;");
      this.uSvc.canRedirect(this.fbSvc.getUserId()).pipe(untilDestroyed(this)).
        subscribe((r: boolean) => {
          this.eliminaManutenzione = r;
        }
      );
      this.eliminabile$.next(false);
    }
    this.deltaOrarioMinuti = 0;
    this.setOrario();
    this.deltaRientroMinuti = 0;
    this.setRientro();
    this.nome_mezzo = this.mdtSvc.getMezzoDaID(this.uscita.idMezzo).nome;
    this.uscita_per_modal = this.uscita;
    this.uSvc.get(this.fbSvc.getUserId()).pipe(untilDestroyed(this)).subscribe(
      utente => this.utente_attuale = utente
    );

  }

  public setOrario() {
    dayjs.extend(utc);
    dayjs.extend(timezone);               
    
    const timeUscita = dayjs(this.uscita.iso8601).tz("Asia/Dubai");
    const timeRientro = dayjs(this.uscita.iso8601rientro).tz("Asia/Dubai");

    this.giorno = timeUscita.format('DD/MM/YYYY');
    this.ora_uscita = 'partenza ' + timeUscita.add(this.deltaOrarioMinuti, 'minute').format('HH:mm');
    this.ora_rientro = 'rientro ' + timeRientro.add(this.deltaOrarioMinuti, 'minute').format('HH:mm');

    this.pulsanteOrarioAbilitato = (this.deltaOrarioMinuti != 0);
    this.testoPulsanteOrario = this.pulsanteOrarioAbilitato ? "Salva" : "Orario";
  }

  public setRientro() {
    dayjs.extend(utc);
    dayjs.extend(timezone);               
    
    const timeRientro = dayjs(this.uscita.iso8601rientro).tz("Asia/Dubai");

    this.giorno = timeRientro.format('DD/MM/YYYY');
    this.ora_rientro = 'rientro ' + timeRientro.add(this.deltaRientroMinuti, 'minute').format('HH:mm');

    this.pulsanteRientroAbilitato = (this.deltaRientroMinuti != 0);
    this.testoPulsanteRientro = this.pulsanteRientroAbilitato ? "Salva" : "Rientro";
  }

  annullabileORipristinabile(): boolean {
    const adesso = dayjs().valueOf();
    const diff = dayjs(this.uscita.iso8601).diff(adesso);
    return diff > 0 && this.nonManut();
  }

  annullaORipristina() {
    if (this.annullabileORipristinabile()) {
      const vuoleAnnullare = !this.uscita.annullata;
      if (vuoleAnnullare && this.nonManut()) {
        this.chiediMotivazione();
      } else {
        this.uscita.annullata = false;
        this.uscSvc.update(this.uscita);
      }
    }
  }

  async chiediMotivazione() {
    const modal = await this.modalController.create({
      component: InputTextModalComponent,
      componentProps: {
        'title': 'Motivazione',
        'text': this.uscita.motivo_annullamento,
        'text_label': 'Inserisci la motivazione',
        'ok_button_label': 'Annulla uscita',
        'cancel_button_label': 'Non annullarla'
      },
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((motivazione) => {
      if (motivazione != null && motivazione.data !== null) {
        this.uscita.motivo_annullamento = motivazione.data + ' (' + this.utente_attuale.nome + ' ' + this.utente_attuale.cognome + ')';
        this.uscita.annullata = true;
        this.uscSvc.update(this.uscita).then(val => {
          console.log("L'uscita " + this.uscita + " è ora annullata con motivazione " + this.uscita.motivo_annullamento);
        }).catch(err => {
          console.log("Impossibile annullare l\'uscita " + this.uscita);
          console.error(err);
        }); 
      }
    });

    return await modal.present();
  }


  testoPosti(p: number): string {
    if (this.uscita.annullata) {
      return "USCITA ANNULLATA";
    }

    if(p > 1) {
      return p + " posti disponibili";
    } else if(p == 1) {
      return "Ultimo posto disponibile";
    } else {
      return "Tutti i posti sono occupati";
    }
  }

  elimina() {
    this.uscSvc.delete(this.uscita);
  }

  diventaMaster() {

      /*
        Ho il nuovo master e devo registrare il suo id come master dell'uscita. Devo anche dargli una posizione
        a bordo di default. Qui però non sono in fase di creazione dell'uscita, perché questa uscita esisteva
        già ed è precedentemente rimasta senza master. Ho quindi vari casi da gestire.

        Prima di tutto devo verificare se il nuovo master non abbia già una posizione assegnata (perché
        magari si era già preso una posizione quando c'era il master precedente). Se è così, lo registro solo
        come nuovo master, ma non faccio altro: gli lascio la posizione che aveva ed è tutto ok.

        Se invece il nuovo non ha ancora una posizione assegnata, devo sceglierne una fra le posizioni libere,
        dando priorità alla posizione principale se disponibile (per esempio nel caso della vela
        sarebbe il timoniere).

        Se non ci sono posizioni libere ed il nuovo master non ha ancora una posizone assegnata, abbiamo un
        bel casino, perché significa che i posti a bordo sono al completo, ma manca un master... 
        questo può capitare quando il master precedente si è tolto ed il numero di posti occupabili sul mezzo
        è stato diminuito dall'admin prima che si trovasse il nuovo master per questa uscita. 
        
        Quello che possiamo fare, in quest'ultimo caso, è solo avvisare il nuovo master che deve prima
        convincere qualcuno a cancellarsi e poi potrà prenotarsi lui come master.

        */
    
    const nuovo_master_id = this.fbSvc.getUserId();

    const ppSub = this.ppSvc.getListByIdUtente(this.uscita, nuovo_master_id).subscribe(posarr => {
      if (posarr && posarr.length > 0) {
        // primo caso, il master ha già una posizione assegnata. Non faccio nulla a parte impostarlo
        // come master dell'uscita.
        this.assegnaMaster(nuovo_master_id);
      } else {
        // altri casi: devo scegliere una posizione per il nuovo master.
        // Recupero tutte le posizioni già prenotate per questa uscita
        const innerPpSub = this.ppSvc.getList(this.uscita).subscribe(prenotate => {
          // Recuperro anche tutte le posizioni possibili

          const possibili = this.psmSvc.getArray();

          // tolgo dalle possibili quelle già prenotate
          const rimanenti = Array<PosizioneSulMezzo>();
          for (let i = 0; i < possibili.length; i++) {
            let giaPresa = false;
            for (let j = 0; j < prenotate.length; j++) {
              if (prenotate[j].denominazione === possibili[i].denominazione) {
                giaPresa = true;
                break;
              }
            }
            if (!giaPresa) {
              rimanenti.push(possibili[i]);
            }
          }

          // cerco fra le rimanenti se ci sia quella principale
          const principale = this.psmSvc.getPosizionePrincipale(this.uscita.idMezzo);
          let assegnata = false;
          for (let i = 0; i < rimanenti.length; i++) {
            if (principale.denominazione === rimanenti[i].denominazione) {
              this.occupa(prenotate, principale, nuovo_master_id);
              assegnata = true;
              break;
            }
          }

          if (!assegnata) {
            // la posizione principale non era libera, se ce n'è un'altra libera (almeno in teoria)
            // tento di assegnargliela (se in pratica sia davvero assegnabile lo controlla poi la
            // funzione "occupa": potrebbe non essere assegnabile se si supera il numero max di 
            // persone a bordo)
            if (rimanenti.length > 0) {
              this.occupa(prenotate, rimanenti[0], nuovo_master_id);
            } else {
              // non ci sono posizioni libere, nemmeno in teoria (di conseguenza men che mai in pratica)
              this.alertMancaPostoABordo();
            }
          }


          innerPpSub.unsubscribe();
        });

      }

      ppSub.unsubscribe();
    });
  }

  occupa(prenotate: Array<PosizionePrenotata>, posizione: PosizioneSulMezzo, nuovo_master: string) {
    const subscription = this.mdtcSvc.get(this.uscita.idMezzo).subscribe(config => {
      subscription.unsubscribe();

      const uSub = this.ospSvc.getList(this.uscita).subscribe(ospiti => {
        uSub.unsubscribe();

        // se in pratica c'è ancora posto a bordo
        if (config.maxPersoneABordo > (prenotate.length + ospiti.length)) {

          // creo la nuova prenotazione
          const v = new PosizionePrenotata();
          Object.assign(v, {...posizione});
          v.id_utente = this.fbSvc.getUserId();
          this.ppSvc.create(v, this.uscita).then((result) => {
            console.log(this.uscita);
            console.log(result);
          }).catch(error => { 
            console.error(error);
          });
          
          // e rendo master il nuovo prenotato
          this.assegnaMaster(nuovo_master);
        } else {
          this.alertMancaPostoABordo();
        }
      });

    });
  }

  assegnaMaster(idmaster: string) {
    this.uscita.id_utente_master = idmaster;
    this.uscSvc.update(this.uscita).then(async (_val) => {
      console.log("Nuovo master uscita: " + this.uscita.id_utente_master);
      this.alertChiediNuovoTarget();
    }).catch(err => {
      console.log("Impossibile impostare nuovo master: " + err);
    });
  }

  aggiornaTarget(target: string) {
    this.uscita.target = target;
    this.uscSvc.update(this.uscita).then(_val => {
      console.log("Nuovo target: " + target);
    }).catch(err => {
      console.log("Impossibile impostare nuovo target: " + err);
    });
  }

  async alertChiediNuovoTarget() {
    const alert = await this.alertController.create({
      cssClass: 'cambio-target-nuovo-master',
      header: 'Cambio target',
      subHeader: 'Sei il nuovo master, quindi se vuoi puoi inserire un nuovo target',
      inputs: [
        {
          placeholder: 'Nuovo target',
          type: 'text',
          value: this.uscita.target,
          name: 'target'
        }],
      buttons: [{
        text: 'OK',
        role: 'confirm',
        handler: target => { this.aggiornaTarget(target.target); return true; }
      },
      {
        text: 'Annulla',
        role: 'cancel',
        handler: _target => true
      }]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async alertMancaPostoABordo() {
    const alert = await this.alertController.create({
      cssClass: 'manca-posto-per-master',
      header: 'Posti insufficienti',
      subHeader: 'Questa uscita è al completo',
      message: 'Non ci sono posti liberi per questa uscita, ma manca un master. Contatta direttamente gli iscritti per trovare qualcuno che si cancelli.',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async apriTechlog(uscita: Uscita) {
    this.uscita_per_modal = uscita;
    const modal = await this.modalController.create({
      component: TechlogModalComponent,
      componentProps: {
        'uscita': this.uscita_per_modal
      },
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((action) => {
      if (action !== null) {
        switch (action.data) {
          case "next":
            const nsubscr = 
            this.uscSvc.getFirstAfter(this.uscita_per_modal).subscribe((nextuscita: Uscita[]) => {
              nsubscr.unsubscribe();
              this.apriTechlog(nextuscita[0]);
            }, err => { nsubscr.unsubscribe(); });
            break;
          case "prev":
            const psubscr = 
            this.uscSvc.getLastBefore(this.uscita_per_modal).subscribe((prevuscita: Uscita[]) => {
              psubscr.unsubscribe();
              this.apriTechlog(prevuscita[0]);
            }, err => { psubscr.unsubscribe(); });
            break;
        }
      }
    });

    return await modal.present();    
  }

  public editTarget(uscita: Uscita, htmlid: string) {
    const el = document.getElementById(htmlid);
    const that = this;
    el.contentEditable = 'true';
    const savefn = (_event) => {
      el.contentEditable = 'false';
      const nuovoTarget = el.innerText;
      if (typeof nuovoTarget !== 'undefined' && nuovoTarget !== null && nuovoTarget.trim().length > 0) {
        uscita.target = el.innerText;
        that.uscSvc.update(uscita);
      } else {
        const msgfn = async () => {
          const alert = await this.alertController.create({
            cssClass: 'target-obbligatorio',
            header: 'Target richiesto',
            subHeader: '',
            message: 'Il target dell\'uscita è obbligatorio: non puoi lasciare vuoto il campo target.',
            buttons: ['OK']
          });
      
          await alert.present();
        };
        msgfn();  
      } 
    };
    const kfn = (event) => {
      if (['Enter', 'NumpadEnter'].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
        savefn(event);
      }
    };
    el.addEventListener('blur', savefn);
    el.addEventListener('keyup', kfn);
    el.addEventListener('keydown', kfn);
    el.focus();
  }

  public nonManut(): boolean {
    return typeof (this.uscita.manutenzione) === 'undefined' ||
                   this.uscita.manutenzione === false ||
                    this.uscita.manutenzione === null; 
  }


  public cambiaOrario(minuti: number) {
    this.deltaOrarioMinuti += minuti;
    this.setOrario();
  }

  public cambiaRientro(minuti: number) {
    this.deltaRientroMinuti += minuti;
    this.setRientro();
  }

  public salvaOrario() {
    const nuovoOrarioUscita = dayjs(this.uscita.iso8601).add(this.deltaOrarioMinuti, 'minute'); 
    const nuovoOrarioRientro = dayjs(this.uscita.iso8601rientro).add(this.deltaOrarioMinuti + this.deltaRientroMinuti, 'minute');
    if (nuovoOrarioRientro.isAfter(nuovoOrarioUscita, 'minutes')) {
      this.uscita.iso8601 = nuovoOrarioUscita.format();
      this.uscita.iso8601rientro = nuovoOrarioRientro.format();
      this.uscSvc.updateTimes(this.uscita, this.uscita.id).then(value => {
        this.deltaOrarioMinuti = 0;
        this.deltaRientroMinuti = 0;
        this.setOrario();
        this.setRientro();
      }).catch(err => { 
        console.error(err);
      });
    } else {
      const msgfn = async () => {
        const alert = await this.alertController.create({
          cssClass: 'rientro-precede-uscita',
          header: 'Orari non validi',
          subHeader: 'Orario di rientro non corretto',
          message: 'L\'orario di rientro non può precedere l\'orario di uscita.',
          buttons: ['OK']
        });
    
        await alert.present();
      };
      msgfn();
    }
  }


  private finirebbePerSovrapporsi(stepMinuti: number) {
    const teorica = new Uscita();
    Object.assign(teorica, this.uscita);

    dayjs.extend(utc);
    dayjs.extend(timezone);               
    
    teorica.iso8601 = dayjs(teorica.iso8601).tz("Asia/Dubai").add(stepMinuti, "minutes").format();
    teorica.iso8601rientro = dayjs(teorica.iso8601rientro).tz("Asia/Dubai").add(stepMinuti, "minutes").format();

    for (let i = 0; i < this.uscite.length; i++) {
      if (this.uscite[i].idMezzo === teorica.idMezzo && this.uscite[i] !== this.uscita) 
        if (this.uscSvc.usciteSovrapposte(this.uscite[i], teorica))
          return true;
    }

    return false;
  }

  private rientrerebbePrimaDiUscire(stepMinuti: number) {

    dayjs.extend(utc);
    dayjs.extend(timezone);               
    
    const orariorientro = dayjs(this.uscita.iso8601rientro).tz("Asia/Dubai").add(stepMinuti, "minutes");
    const orariouscita = dayjs(this.uscita.iso8601).tz("Asia/Dubai");
    return !orariorientro.isAfter(orariouscita, 'minutes');
  }

  public disabilitaTastoAnticipa(deltaOrarioMinuti: number, stepMinuti: number) {
    return deltaOrarioMinuti <= -360 || 
            this.finirebbePerSovrapporsi(deltaOrarioMinuti + stepMinuti) ||
            this.deltaRientroMinuti !== 0;
  }

  public disabilitaTastoRitarda(deltaOrarioMinuti: number, stepMinuti: number) {
    return deltaOrarioMinuti >= 360 || 
            this.finirebbePerSovrapporsi(deltaOrarioMinuti + stepMinuti)  ||
            this.deltaRientroMinuti !== 0;
  }

  public disabilitaTastoAnticipaRientro(deltaRientroMinuti: number, stepMinuti: number) {
    return deltaRientroMinuti <= -360 || 
          this.rientrerebbePrimaDiUscire(deltaRientroMinuti + stepMinuti) ||
          this.deltaOrarioMinuti !== 0;
  }

  public disabilitaTastoRitardaRientro(deltaRientroMinuti: number, stepMinuti: number) {
    return deltaRientroMinuti >= 360 || 
            this.finirebbePerSovrapporsi(deltaRientroMinuti + stepMinuti) ||
            this.deltaOrarioMinuti !== 0;
  }
}
