import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'input-text-modal',
  templateUrl: './input-text-modal.component.html',
  styleUrls: ['./input-text-modal.component.scss']
})
export class InputTextModalComponent implements OnInit {

  inputTextForm: FormGroup;
  @Input() text_label: string;
  @Input() hints: string;
  @Input() ok_button_label: string;
  @Input() cancel_button_label: string;
  @Input() title: string;
  @Input() text: string;

  constructor(
    private modalController: ModalController) { }

  ngOnInit(): void {
    this.inputTextForm = new FormGroup({
      testo: new FormControl(this.text, [Validators.required, Validators.minLength(2)])
    });
  }

  dismissModal(output = null) {
    this.modalController.dismiss(output);
  }

  dismissOk() {
    this.dismissModal(this.inputTextForm.value.testo);
  }

  dismissCancel() {
    this.dismissModal(null);
  }


 
}
