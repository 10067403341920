<!-- This is the proper way if you have submit button outside your ion-content form (typically in the ion-header or ion-footer) -->
<!-- (ref: https://github.com/ionic-team/ionic/issues/16661) -->
<form class="techlog-form ion-page" [formGroup]="techlogForm">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="end">
          <ion-button (click)="dismissModal()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Tech-log</ion-title>
      </ion-toolbar>
    </ion-header>
  
    <ion-content class="create-user-content" [scrollEvents]="true">
  
      <div class="info-wrapper">
        <div class="info even"><div class="infolabel">Data:</div><div class="infodata">{{dataUscita}}</div><div *ngIf="!readonly" class="editbutton">&nbsp;</div></div>
        <div class="info odd"><div class="infolabel">Barca:</div><div class="infodata">{{nomeBarca}}</div><div *ngIf="!readonly" class="editbutton">&nbsp;</div></div>
        <div class="info even"><div class="infolabel">Uscita:</div><div class="infodata">{{oraUscita}}</div><div *ngIf="!readonly" class="real editbutton" (click)="scrollTo('oraUscita')">🖉</div></div>
        <div class="info odd"><div class="infolabel">Rientro:</div><div class="infodata">{{oraRientro}}</div><div *ngIf="!readonly" class="real editbutton" (click)="scrollTo('oraRientro')">🖉</div></div>
        <div class="info even"><div class="infolabel">Master:</div><div class="infodata">{{nomeMaster}}</div><div *ngIf="!readonly" class="editbutton">&nbsp;</div></div>
        <div class="info odd"><div class="infolabel">Equipaggio:</div><div class="infodata">{{equipaggio}}</div><div *ngIf="!readonly" class="editbutton">&nbsp;</div></div>
        <div class="info even"><div class="infolabel">Ospiti:</div><div class="infodata">{{ospiti}}</div><div *ngIf="!readonly" class="editbutton">&nbsp;</div></div>
        <div class="info odd"><div class="infolabel">Area:</div><div class="infodata">{{area}}</div><div *ngIf="!readonly" class="real editbutton" (click)="scrollTo('area')">🖉</div></div>
        <div class="info even"><div class="infolabel">Ispezione Guardia Costiera:</div><div class="infodata">{{ispezione}}</div><div *ngIf="!readonly" class="real editbutton" (click)="scrollTo('ispezione')">🖉</div></div>
        <div class="info odd"><div class="infolabel">Problemi tecnici:</div><div class="infodata">{{problemi_tecn}}</div><div *ngIf="!readonly" class="real editbutton" (click)="scrollTo('probtecn')">🖉</div></div>
        <div class="info even"><div class="infolabel">Altri problemi:</div><div class="infodata">{{problemi_neur}}</div><div *ngIf="!readonly" class="real editbutton" (click)="scrollTo('probneur')">🖉</div></div>
        <div *ngIf="uscita.tl_descrizione_problemi_tecnici" class="info info-area odd"><div class="infolabel">Dettagli problemi tecnici:</div><div class="infodata">{{uscita.tl_descrizione_problemi_tecnici}}</div><div *ngIf="!readonly" class="editbutton"></div></div>
        <div *ngIf="uscita.tl_testo_altre_note" class="info info-area even"><div class="infolabel">Dettagli altri problemi:</div><div class="infodata">{{uscita.tl_testo_altre_note}}</div><div *ngIf="!readonly" class="editbutton"></div></div>
      </div>

      <section class="outstanding-wrapper">
        <ion-button class="outstanding" (click)="outstandingProblems()">Premi per accedere ai<br> problemi TECNICI<br> non risolti</ion-button>
        <ion-button class="resolved" (click)="resolvedProblems()">Premi per accedere allo<br> storico dei problemi TECNICI<br> risolti</ion-button>
      </section>

      <section id="form" [style]="readonly ? 'display: none;' : ''" class="user-details-fields fields-section">
        <ion-list class="inputs-list" lines="full">
          <span id="oraUscita"></span>
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Orario effettivo di uscita</ion-label>
            <ion-datetime [readonly]="readonly" display-timezone="Asia/Dubai" formControlName="fc_orariouscita" displayFormat="HH:mm" minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"></ion-datetime>
          </ion-item>
          <span id="oraRientro"></span>
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Orario effettivo di rientro</ion-label>
            <ion-datetime [readonly]="readonly" display-timezone="Asia/Dubai" formControlName="fc_orariorientro" displayFormat="HH:mm" minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"></ion-datetime>
          </ion-item>
          <span id="area"></span>
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Area</ion-label>
            <ion-textarea [readonly]="readonly" rows="1" cols="20" type="text" formControlName="fc_area"></ion-textarea>
          </ion-item>
          <span id="ispezione"></span>
          <ion-item class="input-item input-ionic-inline">
            <ion-label color="secondary">Ispezione Guardia Costiera</ion-label>
            <ion-checkbox formControlName="fc_ispezione"></ion-checkbox>
          </ion-item>
          <span id="probtecn"></span>
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Problemi tecnici</ion-label>
            <ion-textarea [readonly]="readonly" rows="5" cols="20" type="text" formControlName="fc_problemi_tecn"></ion-textarea>
          </ion-item>
          <span id="probneur"></span>
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Altri problemi</ion-label>
            <ion-textarea [readonly]="readonly" rows="5" cols="20" type="text" formControlName="fc_problemi_neur"></ion-textarea>
          </ion-item>
        </ion-list>
      </section>
  
    </ion-content>
  
    <ion-footer>
      <ion-row class="form-actions-wrapper">
        <ion-col>
            <ion-button *ngIf="lastBefore$ | async as lb" (click)="update(lb[0])" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid">
            &lt;</ion-button>
        </ion-col>
        <ion-col [hidden]="isReadonly()">
          <ion-button (click)="updateAndClose()" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid" 
          [disabled]="false && !techlogForm.valid">
          {{testoTastoSalva()}}</ion-button>
        </ion-col>
        <ion-col>
            <ion-button *ngIf="firstAfter$ | async as fa" (click)="update(fa[0])" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid">
            &gt;</ion-button>
        </ion-col>
      </ion-row>
    </ion-footer>
  </form>
  