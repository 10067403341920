<div class="ic-event" *ngIf="conf.id == uscita.idMezzo">
    <div class="info-wrapper" *ngIf="styleStatoEquipaggio$ | async as styleStato" [style]="styleStato">
        <div class="azioni-ed-info">
            <div class="azioni">
                <div>
                    <ion-button (click)="apriTechlog(uscita_per_modal)">{{testoTastoTechlog}}</ion-button>
                </div>
            </div>
            <div class="info">
                <div class="giorno-ora centrato">{{giorno}}</div>
                <div class="giorno-ora centrato">{{ora_uscita}}</div>
                <div class="giorno-ora centrato">{{ora_rientro}}</div>
                <div class="nome-mezzo centrato">{{nome_mezzo}}</div>
                <div class="master-ui centrato" *ngIf="nonManut()">
                    <div *ngIf="uscita.id_utente_master ; else tastomaster">Master: {{master}}</div>
                    <ng-template #tastomaster>
                        <ion-button *ngIf="tastoAssegnamiMaster$ | async as tam" class="iomaster" (click)="diventaMaster()">Diventa Master</ion-button>
                    </ng-template>
                </div>
                <div class="edit-orario" *ngIf="uscita.id_utente_master && fbSvc.getUserId() === uscita.id_utente_master">
                    <ion-button class="edit-orario-indietro" [disabled]="disabilitaTastoAnticipa(deltaOrarioMinuti, -15)" (click)="cambiaOrario(-15)">-</ion-button>
                    <ion-button class="edit-orario-salva" [disabled]="!pulsanteOrarioAbilitato" (click)="salvaOrario()">{{testoPulsanteOrario}}</ion-button>
                    <ion-button class="edit-orario-avanti" [disabled]="disabilitaTastoRitarda(deltaOrarioMinuti, 15)" (click)="cambiaOrario(15)">+</ion-button>
                </div>
                <div class="edit-orario" *ngIf="uscita.id_utente_master && fbSvc.getUserId() === uscita.id_utente_master">
                    <ion-button class="edit-rientro-indietro" [disabled]="disabilitaTastoAnticipaRientro(deltaRientroMinuti, -15)" (click)="cambiaRientro(-15)">-</ion-button>
                    <ion-button class="edit-rientro-salva" [disabled]="!pulsanteRientroAbilitato" (click)="salvaOrario()">{{testoPulsanteRientro}}</ion-button>
                    <ion-button class="edit-rientro-avanti" [disabled]="disabilitaTastoRitardaRientro(deltaRientroMinuti, 15)" (click)="cambiaRientro(15)">+</ion-button>
                </div>
            </div>    
            <div class="pulsanti-annulla-ripristina-elimina" *ngIf="redirect$ | async as redir">
                <ion-button *ngIf="annullabileORipristinabile()" (click)="annullaORipristina()">{{testoTastoAnnulla}}</ion-button>
                <ion-button *ngIf="eliminabile$ | async as elim" (click)="elimina()">Elimina uscita</ion-button>
                <ion-button *ngIf="eliminaManutenzione" (click)="elimina()">Elimina manut.</ion-button>
            </div>
        </div>
        <div class="stato-equipaggio" *ngIf="nonManut()">
            <div class="immagine-mezzo" *ngIf="styleImmagineMezzo$ | async as styleMezzo" [style]="styleMezzo"></div>
            <div *ngIf="{val: posti$ | async} as posti" class="testo-stato-eq">
                <div>{{testoPosti(posti.val)}}</div>
                <div *ngIf="motivazioneAnnullamentoVisibile" class="testo-stato-eq">
                    Motivazione: {{uscita.motivo_annullamento}}
                </div>
            </div>
        </div>
        <div class="target-wrapper">
            TARGET: <span [id]="'target-text_' + uscita.id" class="target-text">
                {{uscita.target}}
            </span>
            <button *ngIf="pulsanteEditTargetVisibile" class="target-edit" title="Modifica" (click)="editTarget(uscita, 'target-text_' + uscita.id)">
                &nbsp;
            </button>
        </div>        
    </div>
    <div class="equipaggio" *ngIf="nonManut() && configs$ | async as configs">
        <div class="viaggiatore" *ngFor="let v of mezzo.equipaggio">
            <ic-calendar-event-position [posizione]="v" [uscita]="uscita" [mdtConfigs]="configs"  class="poswrapper"></ic-calendar-event-position>
        </div>        
        <ic-calendar-guests class="ospiti-wrapper" [uscita]="uscita"></ic-calendar-guests>
        
    </div>
</div>