<ion-header>
    <ion-toolbar color="primary">
    <ion-buttons slot="end">
        <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
    </ion-buttons>
    <ion-title>{{titolo}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true">

    <div [class]="pending ? 'pending list' : 'solved list'" *ngIf="problemi$ | async as p">
        <div class="problem" *ngFor="let usc of p">
            <div class="info even">
                <div class="infolabel">Data:</div>
                <div class="infodata">{{soloData(usc.iso8601)}}</div>
            </div>
            <div class="info odd">
                <div class="infolabel">Problema:</div>
                <div [class]="'infodata' + (pending ? ' problema' : '')">{{usc.tl_descrizione_problemi_tecnici}}</div>
            </div>
            <div class="info even">
                <div class="infolabel">Master:</div>
                <div class="infodata">{{masters[usc.id_utente_master]}}</div>
            </div>
            <div class="info odd" *ngIf="usc.tl_problemi_risolti_il > ''">
                <div class="infolabel">{{testoRisoltoIl}}:</div>
                <div class="infodata">{{soloData(usc.tl_problemi_risolti_il)}}</div>
            </div>
            <div class="info even" *ngIf="usc.tl_problemi_risolti_da > ''">
                <div class="infolabel">{{testoRisoltoDa}}:</div>
                <div class="infodata">{{usc.tl_problemi_risolti_da}}</div>
            </div>
            <div class="info odd" *ngIf="usc.tl_problemi_risolti_con > ''">
                <div class="infolabel">{{testoSoluzione}}:</div>
                <div [class]="'infodata' + (pending ? '' : ' soluzione')">{{usc.tl_problemi_risolti_con}}</div>
            </div>
            <div class="info even" *ngIf="usc.tl_ticket_problemi_chiuso_da > ''">
                <div class="infolabel">{{usc.tl_mantieni_ticket_aperto ? 'Aggiornato da:' : 'Chiuso da:'}}</div>
                <div class="infodata">{{masters[usc.tl_ticket_problemi_chiuso_da]}}</div>
            </div>
            <div class="info odd" *ngIf="isMaster$ | async as master">
                <ion-button class="resolver" (click)="risolvi(usc)">Aggiungi intervento</ion-button>
            </div>
            <div [class]="'problems-divider even' + (pending ? ' problema': ' soluzione')"></div>
        </div>
    </div>
</ion-content>

<ion-footer>
    <ion-row class="form-actions-wrapper">
    <ion-col>
        <ion-button (click)="dismissModal()" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid">
        Chiudi</ion-button>
    </ion-col>
    </ion-row>
</ion-footer>
