import { OspiteService, Ospite } from './../../api/ospite.service';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Uscita, UscitaService } from '../../api/uscita.service';
import { UserService } from '../../api/user.service';
import { FirebaseService } from '../../api/firebase.service';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { IcCalendarGuestModalComponent } from '../ic-calendar-guest-modal/ic-calendar-guest-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PosizionePrenotataService } from '../../api/posizione-prenotata.service';
import { MezzoDiTrasportoService, MDTConfigService } from '../../api/mezzo-di-trasporto.service';
import { map, shareReplay } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ic-calendar-guests',
  templateUrl: './ic-calendar-guests.component.html',
  styleUrls: ['./ic-calendar-guests.component.scss']
})
export class IcCalendarGuestsComponent implements OnInit {

  @Input() uscita: Uscita;

  public tastoAdd: boolean;
  public ospiti$: Observable<Ospite[]>;
  public posti$: Subject<number> = new Subject<number>();
  public redirect$: Observable<boolean>;
  
  constructor(private userSvc: UserService,
              private ospSvc: OspiteService,
              private fbSvc: FirebaseService,
              private modalController: ModalController,
              private routerOutlet: IonRouterOutlet,
              private ppSvc: PosizionePrenotataService,
              private mdtSvc: MezzoDiTrasportoService,
              private mdtConfSvc: MDTConfigService) { }

  ngOnInit(): void {
    this.redirect$ = this.userSvc.canRedirect(this.fbSvc.getUserId()).pipe(untilDestroyed(this))
                      .pipe(map(redir => {
                        return redir || (this.fbSvc.getUserId() === this.uscita.id_utente_master && this.uscita.id_utente_master != null);
                      }));
    
    this.redirect$.pipe(shareReplay()).pipe(untilDestroyed(this)).subscribe(redir => {
        this.tastoAdd = redir; 
      });
    this.ospiti$ = this.ospSvc.getList(this.uscita);
    this.ppSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(
      prenotazioni => {
        this.ospSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(
          ospiti => {
            if (ospiti.length >= 2) {
              this.posti$.next(0);
            } else {
              const mezzo = this.mdtSvc.getMezzoDaID(this.uscita.idMezzo);
              this.mdtConfSvc.get(mezzo.id).pipe(untilDestroyed(this)).subscribe(conf => {
                const postiliberi = conf.maxPersoneABordo - prenotazioni.length - ospiti.length; 
                this.posti$.next(postiliberi);
              });
            }
          })});

  }

  async aggiungi() {
    const modal = await this.modalController.create({
      component: IcCalendarGuestModalComponent,
      componentProps: {
        'uscita': this.uscita
      },
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        // this.dataReturned = dataReturned.data;
        //  alert('Modal Sent Data :'+ dataReturned);
      }
    });

    return await modal.present();    
  }

  async modifica(o: Ospite) {
    const modal = await this.modalController.create({
      component: IcCalendarGuestModalComponent,
      componentProps: {
        'uscita': this.uscita,
        'ospite': o
      },
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        // this.dataReturned = dataReturned.data;
        //  alert('Modal Sent Data :'+ dataReturned);
      }
    });

    return await modal.present();    
  }

}
