import { Uscita } from './../../api/uscita.service';
import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { IonContent, IonRouterOutlet, ModalController } from '@ionic/angular';
import { FormGroup, FormControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import { UscitaService } from '../../api/uscita.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FirebaseService } from '../../api/firebase.service';
import { CalendarioService } from '../../api/calendario.service';
import { UserService } from '../../api/user.service';
import { MezzoDiTrasportoService } from '../../api/mezzo-di-trasporto.service';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PosizionePrenotataService } from '../../api/posizione-prenotata.service';
import { OspiteService } from '../../api/ospite.service';
import { Observable } from 'rxjs';
import { OutstandingProblemsComponent } from '../outstanding-problems/outstanding-problems.component';
import { ProblemsHistoryComponent } from '../problems-history/problems-history.component';


@UntilDestroy()
@Component({
  selector: 'techlog-modal',
  templateUrl: './techlog-modal.component.html',
  styleUrls: ['./techlog-modal.component.scss']
})
export class TechlogModalComponent implements OnInit {

  @ViewChild(IonContent, { static: false }) content: IonContent;

  @Input() uscita: Uscita;
  
  public dataUscita: string;
  public nomeBarca: string;
  public oraUscita: string;
  public oraRientro: string;
  public nomeMaster: string;
  public equipaggio: string;
  public ospiti: string;
  public area: string;
  public ispezione: string;
  public problemi_tecn: string;
  public problemi_neur: string;
  public lastBefore$: Observable<Uscita[]>;
  public firstAfter$: Observable<Uscita[]>;

  public readonly: boolean;

  techlogForm: FormGroup;

  constructor(
    private modalController: ModalController,
    private uscitaSvc: UscitaService,
    private fbSvc: FirebaseService,
    private calSvc: CalendarioService,
    private userSvc: UserService,
    private mdtSvc: MezzoDiTrasportoService,
    private ppSvc: PosizionePrenotataService,
    private oSvc: OspiteService
  ) { }

  ngOnInit() {
    this.doInit();
  }

  private doInit() {

    if (typeof this.uscita.tl_descrizione_problemi_tecnici === 'undefined') {
      this.uscita.tl_descrizione_problemi_tecnici = '';
    }
    
    if (typeof this.uscita.tl_testo_altre_note === 'undefined') {
      this.uscita.tl_testo_altre_note = '';
    }

    dayjs.extend(utc);
    dayjs.extend(timezone);    

    this.dataUscita = dayjs(this.uscita.iso8601).tz("Asia/Dubai").format("DD/MM/YYYY");
    this.nomeBarca = this.mdtSvc.getMezzoDaID(this.uscita.idMezzo).nome;
    this.oraUscita = dayjs(this.uscita.tl_iso8601uscita || this.uscita.iso8601).tz("Asia/Dubai").format('HH:mm');
    this.oraRientro = dayjs(this.uscita.tl_iso8601rientro || this.uscita.iso8601rientro).tz("Asia/Dubai").format('HH:mm');
    if (this.uscita.id_utente_master) {
      this.userSvc.get(this.uscita.id_utente_master).pipe(untilDestroyed(this))
      .subscribe(master => {
        this.nomeMaster = master.nome + ' ' + master.cognome;
      });
    } else {
      this.nomeMaster = "nessuno";
    }
    this.ppSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(prenotazioni => { 
      this.equipaggio = '' + prenotazioni.length; });
    this.oSvc.getList(this.uscita).pipe(untilDestroyed(this)).subscribe(ospiti => { 
      this.ospiti = '' + ospiti.length });
    this.area = this.uscita.tl_area || "Dubai";
    this.ispezione = this.uscita.tl_ispezione_guardia_costiera ? "Sì" : "No";
    this.problemi_tecn = this.uscita.tl_descrizione_problemi_tecnici.trim().length > 0 ? "Sì" : "No";
    this.problemi_neur = this.uscita.tl_testo_altre_note.trim().length > 0 ? "Sì" : "No";

    this.calSvc.segnaleOrarioIso8601().pipe(untilDestroyed(this)).subscribe(_iso8601 => {
      this.readonly = this.isReadonly();
    }); 


    this.techlogForm = new FormGroup({
      fc_orariouscita: new FormControl(this.oraUscita),
      fc_orariorientro: new FormControl(this.oraRientro),
      fc_area: new FormControl(this.uscita.tl_area),
      fc_ispezione: new FormControl(this.uscita.tl_ispezione_guardia_costiera),
      fc_problemi_tecn: new FormControl(this.uscita.tl_descrizione_problemi_tecnici),
      fc_problemi_neur: new FormControl(this.uscita.tl_testo_altre_note)
    });

    this.lastBefore$ = this.uscitaSvc.getLastBefore(this.uscita);
    this.firstAfter$ = this.uscitaSvc.getFirstAfter(this.uscita);
  }

  isReadonly(): boolean {
    return !(dayjs().diff(this.uscita.iso8601, 'hour') < 24 && 
    this.fbSvc.getUserId() === this.uscita.id_utente_master);
  }

  dismissModal(output = null) {
   this.modalController.dismiss(output);
  }

  private bindData() {
    if (!this.isReadonly()) {

      dayjs.extend(utc);
      dayjs.extend(timezone);  
      dayjs.extend(customParseFormat);  

      const h_uscita = dayjs(this.techlogForm.value.fc_orariouscita, "HH:mm").hour();
      const m_uscita = dayjs(this.techlogForm.value.fc_orariouscita, "HH:mm").minute();
      const b_uscita = dayjs(this.uscita.iso8601).tz("Asia/Dubai").startOf('day');
  
      this.uscita.tl_iso8601uscita = b_uscita.add((h_uscita * 60 + m_uscita), 'minute').tz("Asia/Dubai").format();

      const h_rientro = dayjs(this.techlogForm.value.fc_orariorientro, "HH:mm").hour();
      const m_rientro = dayjs(this.techlogForm.value.fc_orariorientro, "HH:mm").minute();

      this.uscita.tl_iso8601rientro = b_uscita.add((h_rientro * 60 + m_rientro), 'minute').tz("Asia/Dubai").format();
      this.uscita.tl_area = this.techlogForm.value.fc_area;
      this.uscita.tl_ispezione_guardia_costiera = this.techlogForm.value.fc_ispezione;
      this.uscita.tl_descrizione_problemi_tecnici = this.techlogForm.value.fc_problemi_tecn;
      this.uscita.tl_testo_altre_note = this.techlogForm.value.fc_problemi_neur;
      this.uscita.tl_iso8601compilazione = dayjs().format();
    }
  }

  private passaA(uscita: Uscita) {
    if (uscita.id !== this.uscita.id) {
      this.uscita = uscita;
      this.doInit();
    }
  }

  update(passaA: Uscita) {
    if (!this.isReadonly()) {
      this.bindData();
      this.uscitaSvc.update(this.uscita)
      .then(() => {
        this.passaA(passaA);
      });
    } else {
      this.passaA(passaA);
    }
  }

  updateAndClose() {
    if (!this.isReadonly()) {
      this.bindData();
      this.uscitaSvc.update(this.uscita)
      .then(() => {
        this.modalController.dismiss();
      });
    }
  }

  testoTastoSalva() {
    return this.isReadonly() ? "CHIUDI" : "SALVA";
  }

  scrollTo(elementId: string) {
    const elem = document.getElementById(elementId);
    const form = document.getElementById('form');
    this.content.scrollToPoint(0, form.offsetTop + elem.offsetTop, 1000);
  }

  public outstandingProblems() {
    this.showProblems(true);
  }

  public resolvedProblems() {
    this.showProblems(false);
  }

  async showProblems(outstanding: boolean) {

    const modal = await this.modalController.create({
      component: ProblemsHistoryComponent,
      componentProps: {
        'pending': outstanding,
        'mezzo': this.uscita.idMezzo
      },
      swipeToClose: true
    });

    return await modal.present();    
  }

}
