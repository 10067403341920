import { Injectable } from '@angular/core';
import { Uscita, UscitaService } from './uscita.service';
import { FirebaseService } from './firebase.service';
import { Observable } from 'rxjs';
import { MezzoDiTrasportoService } from './mezzo-di-trasporto.service';
import { WhereFilterOp } from '@firebase/firestore-types';
import dayjs from 'dayjs';

export class PosizionePrenotata {
  public id: string;
  public denominazione: string;
  public id_utente: string;
}


@Injectable({
  providedIn: 'root'
})
export class PosizionePrenotataService {

  private static readonly colori: Array<string> = ["lightgreen", "gold", "cornflowerblue", "red", "lightgrey", "red"];
  public static readonly vacante = 0;
  public static readonly quasiAlCompleto = 1;
  public static readonly completa = 2;
  public static readonly annullata = 3;
  public static readonly senzaMaster = 4;
  public static readonly componentiEquipaggioInferioriAlMinimo = 5;

  public static oreAnticipoAnnullamentoAutomaticoUscita = 24;
  public static numeroMinimoComponentiEquipaggio = 3;


  constructor(private fbs: FirebaseService,
              private mdtSvc: MezzoDiTrasportoService,
              private uSvc: UscitaService) { 

  }

  public getFirebaseCollectionPath(uscita: Uscita): string {
    return this.uSvc.getFirebasePathAndPK(uscita) + '/posizioni';
  }

  public getFirebasePK(pos: PosizionePrenotata | string): string {
    if (typeof pos === 'string') {
      return pos.toLowerCase().replace(' ','_');
    } else {
      return this.getFirebasePK(pos.denominazione);
    }
  }

  public getFirebasePathAndPK(uscita: Uscita, pos: PosizionePrenotata): string {
    return this.getFirebaseCollectionPath(uscita) + '/' + this.getFirebasePK(pos);
  }

  public create(pp: PosizionePrenotata, uscita: Uscita): Promise<PosizionePrenotata>  {
    return this.fbs.create(pp, this.getFirebaseCollectionPath(uscita), this.getFirebasePK(pp), "Nuova Prenotazione", { posizione: pp.denominazione, barca: uscita.idMezzo, orario: uscita.iso8601});
  }

  public get(uscita: Uscita, denominazione: string): Observable<PosizionePrenotata> {
    return this.fbs.get(this.getFirebaseCollectionPath(uscita), this.getFirebasePK(denominazione), () => new PosizionePrenotata());
  }

  public getList(uscita: Uscita): Observable<PosizionePrenotata[]> {
    return this.fbs.getList(this.getFirebaseCollectionPath(uscita), () => new PosizionePrenotata());
  }

  /**
   * Nel caso in cui più utenti possano occupare la stessa posizione, questa funzione è utile per avere
   * tutte le prenotazioni relative allo stesso nome di posizione.
   */
  public getListByDenominazione(uscita: Uscita, denominazione: string): Observable<PosizionePrenotata[]> {
    const where: Array<[string, WhereFilterOp, string]> = 
        Array(['denominazione', '==', denominazione]);
    return this.fbs.getListWhere(this.getFirebaseCollectionPath(uscita), where, () => new PosizionePrenotata());
  }

  /**
   * Recupera tutte le posizioni prenotate (di solito ne esiste al massimo una) da un utente
   */
  public getListByIdUtente(uscita: Uscita, id_utente: string): Observable<PosizionePrenotata[]> {
    const where: Array<[string, WhereFilterOp, string]> = 
        Array(['id_utente', '==', id_utente]);
    return this.fbs.getListWhere(this.getFirebaseCollectionPath(uscita), where, () => new PosizionePrenotata());
  }

  public update(pp: PosizionePrenotata, uscita: Uscita): Promise<any> {
    return this.fbs.update(pp, this.getFirebaseCollectionPath(uscita), this.getFirebasePK(pp));
  }

  public delete(pp: PosizionePrenotata, uscita: Uscita): Promise<any> {
    return this.fbs.delete(this.getFirebaseCollectionPath(uscita), this.getFirebasePK(pp), "Prenotazione Eliminata", { posizione: pp.denominazione, barca: uscita.idMezzo, orario: uscita.iso8601});
  }

  public getStatoUscita(uscita: Uscita, componentiEquipaggio: number, numeroOspiti: number, maxPersone: number): number {
    if (uscita.annullata) {
      return PosizionePrenotataService.annullata;
    }

    const adesso = dayjs().valueOf();
    const orarioUscita = dayjs(uscita.iso8601);
    const anticipo = orarioUscita.diff(adesso, 'hour');
  
    if ((!uscita.id_utente_master) || uscita.id_utente_master.trim().length === 0) {
      if (anticipo < PosizionePrenotataService.oreAnticipoAnnullamentoAutomaticoUscita) {
        // return PosizionePrenotataService.annullata; // disabilitato su richiesta del cliente
        return PosizionePrenotataService.senzaMaster;
      } else {
        return PosizionePrenotataService.senzaMaster; 
      }
    }

    if ((componentiEquipaggio < PosizionePrenotataService.numeroMinimoComponentiEquipaggio) && 
        (anticipo < PosizionePrenotataService.oreAnticipoAnnullamentoAutomaticoUscita) &&
            false ) { // disabilitato su richiesta del cliente
          return PosizionePrenotataService.componentiEquipaggioInferioriAlMinimo;
    }

    const mezzo = this.mdtSvc.getMezzoDaID(uscita.idMezzo);
    if (!maxPersone) {
      maxPersone = mezzo.maxPersoneABordo;
    }

    const postiOccupati = componentiEquipaggio + numeroOspiti;

    if (postiOccupati < maxPersone - 1) {
      return PosizionePrenotataService.vacante;
    } else if (postiOccupati === maxPersone - 1) {
      return PosizionePrenotataService.quasiAlCompleto;
    } else {
      return PosizionePrenotataService.completa;
    }
  }

  public getColoreUscita(uscita: Uscita, componentiEquipaggio: number, numeroOspiti: number, maxPersone: number): string {
    return PosizionePrenotataService.colori[this.getStatoUscita(uscita, componentiEquipaggio, numeroOspiti, maxPersone)];
  }

}
