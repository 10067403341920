import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CalendarioService, ICGiorno } from '../../api/calendario.service';
import * as dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Observable } from 'rxjs';


@Component({
  selector: 'ic-calendar',
  templateUrl: './ic-calendar.component.html',
  styleUrls: ['./ic-calendar.component.scss'],
})
export class IcCalendarComponent implements OnInit {

  public giorni$: Observable<ICGiorno[]>;
  public hideYearControls: boolean;

  constructor(private calsrv: CalendarioService) { }

  ngOnInit() {
    this.giorni$ = this.calsrv.getGiorniMese();
    this.hideYearControls = true;
  }

  
  public nomeMese(giorni: ICGiorno[]): string {
    const sicuramenteDentroIlMese: ICGiorno = giorni[10];
    return dayjs(sicuramenteDentroIlMese.iso8601).format('MMMM');
  }

  public numeroAnno(giorni: ICGiorno[]): string {
    const sicuramenteDentroIlMese: ICGiorno = giorni[10];
    return dayjs(sicuramenteDentroIlMese.iso8601).format('YYYY');
  }

  public nomeGiorno(giorno: ICGiorno): string {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const djsgiorno = dayjs(giorno.iso8601).tz("Asia/Dubai");
    return djsgiorno.format('ddd');
  }

  public mostraControlliAnno() {
    this.hideYearControls = !this.hideYearControls;
  }

  public mostraMese(deltaMese: number) {
    this.calsrv.setMese(deltaMese);
  }
}
