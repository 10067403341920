<!-- This is the proper way if you have submit button outside your ion-content form (typically in the ion-header or ion-footer) -->
<!-- (ref: https://github.com/ionic-team/ionic/issues/16661) -->
<form class="create-user-form ion-page" [formGroup]="createGuestForm">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="end">
          <ion-button (click)="dismissModal()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Dettagli ospite</ion-title>
      </ion-toolbar>
    </ion-header>
  
    <ion-content class="create-user-content">
  
      <section class="user-details-fields fields-section">
        <ion-list class="inputs-list" lines="full">
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Nome</ion-label>
            <ion-input type="text" formControlName="nome"></ion-input>
          </ion-item>
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Cognome</ion-label>
            <ion-input type="text" formControlName="cognome" required></ion-input>
          </ion-item>
          <!--
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Data di nascita</ion-label>
            <ion-datetime display-format="DD/MM/YYYY" picker-format="DD MMMM YYYY" formControlName="nascita"></ion-datetime>
          </ion-item>
          -->
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Telefono</ion-label>
            <ion-input type="tel" formControlName="telefono"></ion-input>
          </ion-item>
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Email</ion-label>
            <ion-input type="email" formControlName="email"></ion-input>
          </ion-item>
          <ion-item class="input-item">
            <ion-checkbox formControlName="minorenne" slot="start" (ionChange)="minorenneChange($event)"></ion-checkbox>
            <ion-label formControlName="minorenne" color="secondary">Minorenne?</ion-label>
          </ion-item>
          <ion-item *ngIf="avvisominorenne">
            <p>Se minorenne sotto i 12 anni, per legge U.A.E., questi deve sempre indossare il giubotto di salvataggio quando a bordo</p>
          </ion-item>
          <ion-item class="input-item">
            <ion-label color="secondary" position="floating">Amico o parente di / Accompagnato da</ion-label>
            <ion-input type="text" formControlName="amico_parente_accompagnatore"></ion-input>
          </ion-item>
        </ion-list>
      </section>
  
    </ion-content>
  
    <ion-footer>
      <ion-row class="form-actions-wrapper">
        <ion-col [hidden]="editMode">
          <ion-button (click)="createGuest()" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid" 
          [disabled]="!createGuestForm.valid">
          AGGIUNGI</ion-button>
        </ion-col>
        <ion-col [hidden]="!editMode">
            <ion-button (click)="updateGuest()" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid" 
            [disabled]="!createGuestForm.valid">
            SALVA</ion-button>
        </ion-col>
        <ion-col [hidden]="!editMode">
            <ion-button (click)="removeGuest()" class="submit-btn" expand="block" color="secondary" type="submit" fill="solid">
            ELIMINA</ion-button>
        </ion-col>
      </ion-row>
    </ion-footer>
  </form>
  