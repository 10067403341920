import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { merge, Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import { MezzoDiTrasportoService, MezzoDiTrasporto } from './mezzo-di-trasporto.service';
import { map, mergeMap } from 'rxjs/operators';
import { FirebaseService } from './firebase.service';
import { WhereFilterOp } from '@firebase/firestore-types';

registerLocaleData(localeIT, 'it');

export class Uscita {
  public id: string;
  public idMezzo: string;
  public iso8601: string;
  public iso8601rientro: string;
  public id_utente_master: string;
  public target: string;
  public annullata: boolean;
  public motivo_annullamento: string;
  public tl_iso8601uscita: string;
  public tl_iso8601rientro: string;
  public tl_iso8601compilazione: string;
  public tl_area: string;
  public tl_ispezione_guardia_costiera: boolean;
  public tl_descrizione_problemi_tecnici: string;
  public tl_testo_altre_note: string;
  public tl_problemi_risolti_il?: string | null | undefined;
  public tl_problemi_risolti_da?: string | null | undefined;
  public tl_problemi_risolti_con?: string | null | undefined;
  public tl_mantieni_ticket_aperto?: boolean | null | undefined;
  public tl_ticket_problemi_chiuso_da?: string | null | undefined;
  public manutenzione?: boolean | null | undefined;
}

/*
export interface IMezziDisponibili {
  [yyyyMMdd: string] : Observable<MezzoDiTrasporto[]>;
} */

@Injectable({
  providedIn: 'root'
})
export class UscitaService {

  constructor(private mdtSvc: MezzoDiTrasportoService,
              private fbs: FirebaseService) {}
  
  public getFlatDateTime(uscitaIso8601: string): string {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const timeUscita = dayjs(uscitaIso8601).tz("Asia/Dubai");
    return timeUscita.format('YYYYMMDD_HHmm');
  }

  public getFlatDate(uscitaIso8601: string): string {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const timeUscita = dayjs(uscitaIso8601).tz("Asia/Dubai");
    return timeUscita.format('YYYYMMDD');
  }

  /*
  public getFlatTime(uscitaIso8601: string): string {
    return formatDate(dayjs(uscitaIso8601).toDate(), 'HHmm', 'it');
  }*/

  public getFirebaseCollectionPath(): string {
    return 'uscite';
  }

  public getFirebasePK(uscita: Uscita): string {
    if (uscita.id) {
      return uscita.id;
    }
    return this.getFlatDateTime(uscita.iso8601) + '_' + 
           uscita.idMezzo;
  }

  public getFirebasePathAndPK(uscita: Uscita): string {
    return this.getFirebaseCollectionPath() + '/' + this.getFirebasePK(uscita);
  }

  public create(uscita: Uscita): Promise<Uscita>  {
    return this.fbs.create(uscita, this.getFirebaseCollectionPath(), this.getFirebasePK(uscita), "Nuova Uscita", { orario: uscita.iso8601 });
  }

  public getList(): Observable<Uscita[]> {
    return this.fbs.getList(this.getFirebaseCollectionPath(), () => new Uscita());
  }

  public getListForDay(iso8601day: string): Observable<Uscita[]> {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const startOfDay = dayjs(iso8601day).tz("Asia/Dubai").startOf('day').format();
    const endOfDay = dayjs(iso8601day).tz("Asia/Dubai").endOf('day').format();
    const where: Array<[string, WhereFilterOp, string]> = 
        Array(['iso8601', '>=', startOfDay],
              ['iso8601', '<=', endOfDay]);
    return this.fbs.getListWhere(this.getFirebaseCollectionPath(), where, () => new Uscita());
  }

  public getListForIntervalAndMezzo(start_iso8601: string, end_iso8601: string, idmezzo: string): Observable<Uscita[]> {
    const where: Array<[string, WhereFilterOp, string]> = 
        Array(['iso8601', '>=', start_iso8601],
              ['iso8601', '<=', end_iso8601],
              ['idMezzo', '==', idmezzo]);
    return this.fbs.getListWhere(this.getFirebaseCollectionPath(), where, () => new Uscita());
  }

  public getListForDayAndMezzo(iso8601day: string, idmezzo: string): Observable<Uscita[]> {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const startOfDay = dayjs(iso8601day).tz("Asia/Dubai").startOf('day').format();
    const endOfDay = dayjs(iso8601day).tz("Asia/Dubai").endOf('day').format();
    return this.getListForIntervalAndMezzo(startOfDay, endOfDay, idmezzo);
  }

  public getFirstAfter(uscita: Uscita): Observable<Uscita[]> {   
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const from = dayjs(uscita.iso8601).tz("Asia/Dubai").format()
    const where: Array<[string, WhereFilterOp, string]> = 
        Array(['iso8601', '>', from],
              ['idMezzo', '==', uscita.idMezzo]);
    return this.fbs.getListWhere(this.getFirebaseCollectionPath(), where, () => new Uscita(), 'iso8601', true, 1);
  }

  public getLastBefore(uscita: Uscita): Observable<Uscita[]> {    
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const upto = dayjs(uscita.iso8601).tz("Asia/Dubai").format()
    const where: Array<[string, WhereFilterOp, string]> = 
        Array(['iso8601', '<', upto],
              ['idMezzo', '==', uscita.idMezzo]);
    return this.fbs.getListWhere(this.getFirebaseCollectionPath(), where, () => new Uscita(), 'iso8601', false, 1);
  }

  public update(uscita: Uscita): Promise<any> {
    return this.fbs.update(uscita, this.getFirebaseCollectionPath(), this.getFirebasePK(uscita));
  }

  public updateTimes(uscita: Uscita, pk: string): Promise<any> {
    return this.fbs.update(uscita, this.getFirebaseCollectionPath(), pk, "Uscita spostata", { id: uscita.id, nuovoOrario: uscita.iso8601});
  }

  public delete(uscita: Uscita): Promise<any> {
    return this.fbs.delete(this.getFirebaseCollectionPath(), this.getFirebasePK(uscita), "Uscita eliminata", {id: uscita.id});
  }

  public getMezziDisponibili(iso8601day: string, mezziPrenotati: Uscita[]): Observable<MezzoDiTrasporto[]> {
    const tuttiIMezzi = this.mdtSvc.getMezzi();

    return tuttiIMezzi.pipe(map(data => {
      return data.map(item => {
        let prenotato = 0;
        for (let i = 0; i < mezziPrenotati.length; i++) {
          if (mezziPrenotati[i].idMezzo === item.id)
            prenotato++;
        }
        item.prenotabile = item.maxUscitePerGiorno - prenotato;
        return item;
      });
    }));

  }

  public getUsciteConProblemi(idMezzo: string): Observable<Uscita[]> {
    const specialWords = ['no', 'nessuno', 'niente', 'any', 'nothing', 'none', 'nil'];
    const where: Array<[string, WhereFilterOp, string]> = 
        Array(['tl_descrizione_problemi_tecnici', '!=', null],
              ['tl_descrizione_problemi_tecnici', '>', ''],
              ['idMezzo', '==', idMezzo]);
    return this.fbs.getListWhere(this.getFirebaseCollectionPath(), where, () => new Uscita(), 'tl_descrizione_problemi_tecnici', true)
           .pipe(map(uscite => uscite.filter(
            (u: Uscita) => {
              const descrizioneProblema = u.tl_descrizione_problemi_tecnici.toLowerCase()
              .replace(/[^\w\s\']|_/g, "") // anche se https://stackoverflow.com/a/4328546/1483447 non
              .replace(/\s+/g, " ") // è un modo preciso di togliere la punteggiatura, per questo scopo va più che bene
              .trim();
              return descrizioneProblema.length > 0 &&
                !specialWords.includes(descrizioneProblema);
              }
            )
           ));
  }

  public getProblemiAperti(idMezzo: string): Observable<Uscita[]> {
    return this.getUsciteConProblemi(idMezzo).pipe(map(
      uscite => uscite.filter(
        u => (typeof u.tl_problemi_risolti_con === 'undefined' || 
                      u.tl_problemi_risolti_con === null ||
                      u.tl_problemi_risolti_con === '' ||
              (typeof u.tl_problemi_risolti_con === 'string' &&
                u.tl_problemi_risolti_con.length > 0 &&
                u.tl_mantieni_ticket_aperto === true))).sort(
          (a, b) => b.iso8601.localeCompare(a.iso8601))));
  }

  public getProblemiChusi(idMezzo: string): Observable<Uscita[]> {
    return this.getUsciteConProblemi(idMezzo).pipe(map(
      uscite => uscite.filter(
        u => (typeof u.tl_problemi_risolti_con === 'string' &&
                      u.tl_problemi_risolti_con.trim().length > 0 &&
                      u.tl_mantieni_ticket_aperto !== true)).sort(
          (a, b) => b.iso8601.localeCompare(a.iso8601))));
  }

  public usciteSovrapposte(u1: Uscita, u2: Uscita, margine: number = 30): boolean {
    const u1inizio = dayjs(u1.iso8601);
    const u2inizio = dayjs(u2.iso8601);
    const u1fine = dayjs(u1.iso8601rientro);
    const u2fine = dayjs(u2.iso8601rientro);

    return ((u1inizio.diff(u2fine, 'minutes') < margine) &&
            (u1inizio.isAfter(u2inizio))) ||
           ((u2inizio.diff(u1fine, 'minutes') < margine) &&
            (u2inizio.isAfter(u1inizio))) ||
            u1inizio.isSame(u2inizio);
  }

}