import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { FirebaseService } from './firebase.service';
import { WhereFilterOp } from '@firebase/firestore-types';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../environments/environment';


export class Utente {
  public id: string;
  public nome: string;
  public cognome: string;
  public email: string;
  public role: string;
  public dataIngresso: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private fbSvc: FirebaseService,
              private afAuth: AngularFireAuth) { }


  public getList(): Observable<Utente[]> {
    return this.fbSvc.getList('/users', () => new Utente());
  }

  public get(uId: string): Observable<Utente> {
    return this.fbSvc.get('/users', uId, () => new Utente());
  }

  public create(utente: Utente, pk: string): Promise<Utente> {
    return this.fbSvc.create(utente, '/users', pk);
  }

  public update(utente: Utente, analyticsData: any = null): Promise<Utente> {
    if (analyticsData === null) {
      analyticsData = {
        callstack: 'UserService.update'
      };
    }
    analyticsData.payload = JSON.stringify(utente);
    analyticsData.currentUser = this.fbSvc.getUserId();
    return this.fbSvc.update(utente, '/users', null, "Update user", analyticsData);
  }
  /**
   * 
   * @param email 
   * @param password 
   * @returns The new user UID
   */
  public signUp(email: string, password: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {

        result.user.sendEmailVerification().then(val => {
          resolve(result.user.uid);
        }).catch(err => {
          reject(err);
        });

      }).catch((error) => {
        reject(error);
      });
    });
  }

  public async passwordReset(email: string): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email);
  }



  /**
   * Funzione usata per abilitare i tasti dell'admin. Notare che tutto questo gira client-side
   * (quindi facilmente craccabile) e che su Firebase è un gran casino andare a definire i permessi
   * di utenti crew (non admin) sui dati delle uscite, così come richiesti su quesa app, quindi, 
   * in pratica, per i dati delle uscite la sicurezza vera non esiste.
   * Firebase è configurato per lasciar fare tutto (sui dati delle uscite) a qualsiasi utente 
   * registrato ed il controllo è solo qui, client-side.
   * La cosa che un utente NON admin NON può fare su Firebase, nemmeno craccando questa app, in quanto
   * il controllo è lato Firebase e non qui sul client, è gestire gli account degli utenti, 
   * che comunque è la parte più critica dal punto di vista della sicurezza.
   * Ad ogni modo, per scoraggiare eventuali smanettoni, questa funzione, che dovrebbe chiamarsi
   * isAdmin, ha invece un nome offuscato e lato firebase anche il nome del ruolo è offuscato.
   * Non mi metto ad offuscare tutto il codice della app, perché i "contro" sono troppi e non 
   * giustificabili in questo caso.
   * @param uId 
   * @returns 
   */
  public canRedirect(uId: string): Observable<boolean> {
    if (typeof uId === "undefined" || uId === null) {
      return observableOf(false);
    }
    return this.fbSvc.get('/users', uId, () => new Utente()).pipe(map(u => this.getCanRedirect(u)));
  }

  public getCanRedirect(role: string | Utente | undefined): boolean {
    if (typeof role === 'undefined') {
      return false;
    }
    if (typeof role === 'string') {
      return role === 'pu01m3773570N37$ed3Re4c8IUz6u3';    
    }
    return this.getCanRedirect(role.role);
  }

  /**
   * Funzione usata per abilitare i tasti dei master. Discorso analogo a canRedirect (vedi sopra).
   * @param uId 
   * @returns 
   */
  public canBook(uId: string): Observable<boolean> {
    return this.fbSvc.get('/users', uId, () => new Utente()).pipe(map(role => this.getCanBook(role.role)));    
  }

  public getCanBook(role: string): boolean {
    return role === '70m377eZ3$eDW5€507o46UaLc1' || this.getCanRedirect(role);    
  }


  public setCanBook(u: Utente, canBook: boolean) {
    const ur = new Utente();
    Object.assign(ur, {...u});
    ur.role = canBook ? '70m377eZ3$eDW5€507o46UaLc1' : 'crew'; 
    return this.fbSvc.update(ur, '/users', u.id);
  }

  public getNonRedirectingList(): Observable<Utente[]> {
    const where: Array<[string, WhereFilterOp, any]> = 
        Array(['role', '!=', 'pu01m3773570N37$ed3Re4c8IUz6u3']);
        // Array(['role', 'in', ['70m377eZ3$eDW5€507o46UaLc1','crew']]);
    return this.fbSvc.getListWhere('/users', where, () => new Utente(), 'role,dataIngresso', true);
  }
}
