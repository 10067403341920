import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { FirebaseService } from './api/firebase.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private fbSvc: FirebaseService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  handleError(error: any) {
    this.ngZone.run(() => {
      if (error.message) {
        if (error.message.indexOf("Cannot read properties of undefined (reading 'role')") === 0) {
            this.fbSvc.logout();
            this.router.navigateByUrl('/');
            
        } 
      }
      console.error('Error from global error handler', error);
    });    
  }
}