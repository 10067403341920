import { map, shareReplay } from 'rxjs/operators';
import { Uscita } from './../../api/uscita.service';
import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { FormGroup, FormControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import { UscitaService } from '../../api/uscita.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FirebaseService } from '../../api/firebase.service';
import { UserService, Utente } from '../../api/user.service';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Observable, ReplaySubject } from 'rxjs';
import { OutstandingProblemsComponent } from '../outstanding-problems/outstanding-problems.component';


@UntilDestroy()
@Component({
  selector: 'problems-history',
  templateUrl: './problems-history.component.html',
  styleUrls: ['./problems-history.component.scss']
})
export class ProblemsHistoryComponent implements OnInit {
  @Input() mezzo: string;
  @Input() pending: boolean;

  public problemi$: Observable<Uscita[]>;

  public isMaster$: Observable<boolean>;

  public titolo: string;
  public testoRisoltoIl: string;
  public testoRisoltoDa: string;
  public testoSoluzione: string;

  public masters: {
    [index: string]: string;
  };

  constructor(private modalController: ModalController,
    private uscitaSvc: UscitaService,
    private fbSvc: FirebaseService,
    private userSvc: UserService) { }

  ngOnInit(): void {
    this.masters = {};

    this.titolo = (this.pending ? 'Problemi aperti ' : 'Problemi risolti ') + this.mezzo;
    this.testoRisoltoIl = this.pending ? 'Aggiornato il' : 'Risolto il';
    this.testoRisoltoDa = this.pending ? 'Aggiornato da' : 'Risolto da';
    this.testoSoluzione = this.pending ? 'Intervento' : 'Soluzione';

    dayjs.extend(utc);
    dayjs.extend(timezone);   
    
    this.problemi$ = this.pending ? this.uscitaSvc.getProblemiAperti(this.mezzo) : this.uscitaSvc.getProblemiChusi(this.mezzo);

    this.problemi$.pipe(shareReplay()).pipe(untilDestroyed(this)).subscribe(uscite => {
      for (let i = 0; i < uscite.length; i++) {
        const idMaster = uscite[i].id_utente_master;
        if (!this.masters[idMaster])
          this.mostraMaster(idMaster);
        
        const idUtente = uscite[i].tl_ticket_problemi_chiuso_da;
        if (idUtente && !this.masters[idUtente])
          this.mostraMaster(idUtente);
      }
    });

    this.isMaster$ = this.userSvc.canBook(this.fbSvc.getUserId()); 
  }

  dismissModal(output = null) {
    this.modalController.dismiss(output);
   }
 
  soloData(data: string): string {
    dayjs.extend(utc);
    dayjs.extend(timezone);  
    dayjs.extend(customParseFormat);  
    return dayjs(data).format('YYYY-MM-DD');
  }


  async risolvi(usc: Uscita) {

    const modal = await this.modalController.create({
      component: OutstandingProblemsComponent,
      componentProps: {
        'uscita': usc
      },
      swipeToClose: true
    });

    return await modal.present();    
  }

  public async nomeMaster(idum: string): Promise<string> {
    return this.userSvc.get(idum).pipe(map((u: Utente) => {
      const result = u.nome + ' ' + u.cognome;
      this.masters[idum] = result;
      return result;
    } 
      )).toPromise();    
  }

  public mostraMaster(idum: string): void {
    this.nomeMaster(idum).then((_nome: string) => {});
  }


}
